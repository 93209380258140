import { Button, Dialog, Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
export default function RemapVinImei({
    open,
    vin,
    imei,
    engineNo,
    handleImei,
    closeEditModel,
    handleRemap,
    // allImei,
    status,
    handleEditEngineNo,
    setImei
}) {
    
    /**
   * @summary This sets the value of imei
   */
    const handleImeiChange = (e) => {
        setImei(e);
    };

    return (
        <Dialog
            open={open}
            maxWidth={"md"}
            fullWidth={true}
            aria-labelledby="max-width-dialog-title"
            disableEscapeKeyDown={true}
        >
            <Grid container>
                <Grid item xs={12} style={{ padding: "25px" }}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography variant="h5">Edit IMEI</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button style={{ float: "right" }} onClick={closeEditModel}>
                                <CloseIcon
                                    style={{
                                        fill: "#2b2924",
                                    }}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <CustomTextField value={vin} label="VIN" disabled={true} />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <CustomSelect
                                        label="IMEI"
                                        placeholder="Select the imei"
                                        value={imei}
                                        options={allImei}
                                        getOptionLabel={(options) => options}
                                        handleSelectChange={handleImei}
                                    /> */}
                                    <CustomTextField
                                        value={imei}
                                        label="IMEI*"
                                        placeholder="IMEI"
                                        onChange={(e) => handleImeiChange(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        value={engineNo}
                                        label="Engine Number"
                                        disabled={status !== "VIN-IMEI Mapped"}
                                        onChange={handleEditEngineNo}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent="flex-end"
                                spacing={1}
                            >
                                <Grid item xs={2}>
                                    <ColorButton onClick={closeEditModel}>
                                        <Typography>Cancel</Typography>
                                    </ColorButton>
                                </Grid>
                                <Grid item xs={2}>
                                    <ColorButton onClick={handleRemap} disabled={!imei}>
                                        <Typography>Submit</Typography>
                                    </ColorButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}
