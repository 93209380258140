import {
  Typography,
  Grid,
  InputAdornment,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Menu,
  MenuItem,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import useStyles from "./styles";

import SearchIcon from "@mui/icons-material/Search";
import {
  ColorButton,
  CustomButton,
  ColorSampleButton,
} from "../../hoc/PaletteComponent";
import React, { useEffect, useState, useMemo , useCallback} from "react";
import AddVehicle from "./AddVinImeiMap";
import {
  CustomCircularProgress,
  CustomDialogBox,
  NoData,
  ValidateButton,
} from "../../hoc/CustomComponents";
import {
  gql,
  useLazyQuery,
  useApolloClient,
  useMutation,
  useQuery,
  Query,
} from "@apollo/client";
import moment from "moment";
import { VinBulkUploads } from "./VinBulkUpload";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import { client2 } from "../tables/client";
import { client3 } from "./client";
import axios from "axios";
import VinImeMapSample from "../../images/Vin_Imei_map.xlsx";

import { debounce } from "throttle-debounce";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ExcelDownload, fetchSearchResultsNumber1 } from "../../utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileSaver from "file-saver";
import CreateIcon from "@mui/icons-material/Create";
import editImei from "../tables/editImei";
import CreateKyc from "./CreateKyc";
import EOLTDetails from "./EOLTDetails";
import { CircularProgress, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import EoltLogsReport from "./EoltLogsReport";
import RemapImei from "../EOLTReport/RemapImei";
import RemapVinImei from "./RemapVinImei";
import AssignVinToDealer from "./AssignVinToDealer";
import VinNoChange from "./VinNoChange";

/**
 * @summary getAllVinImeiMapData schema
 */
const GET_ALL_MAPPED_DATA = gql`
  query ($oemId: Int!, $limit: Int!, $offset: Int!, $vinSearch: String) {
    getAllVinImeiMapData(
      oemId: $oemId
      limit: $limit
      offset: $offset
      vinSearch: $vinSearch
    ) {
      code
      message
      mappedData {
        totalCount
        rows {
          vin
          imei
          vehicleType
          vehicleModel
          fuelType
          createdOn
          status
          serialNumber
          iccid
          engineNumber
          yearOfmg
          vehicleMake
          customerID
          customerName
          customerMobile1
          retailInvoiceNumber
          customerAddress
          customerEmail
          proofOfAddress
          proofOfIdentity
          dealerCode
          dealerName
          kycReceivedDate
          deviceModel
          dealerAddress
          dealerTown
          dealerContactName
          dealerContactNumber
          kyccount
          kycApprovedDate
          kycRejectedDate
          lastKycReceivedDate
          vehicleActivationDate
          kycMode
          ticketCreated
          rejectionReason
          simPlanType
          simSubscriptionStartDate
          simSubscriptionEndDate
          EOLTFirstTS
          deviceStatus
          isEoltCheck
          eoltReportTicket
          eoltReportName
          eoltCompletedOn
          vinDealerCode
        }
      }
    }
  }
`;

/**
 * @summary getPublicUploadURL schema
 */
const GET_UPLOAD_URL = gql`
  mutation ($fileExtension: String!) {
    getPublicUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      publicUploadURL
    }
  }
`;

/**
 * @summary getUploadURL schema
 */
const UPLOAD_URL = gql`
  mutation ($fileExtension: String!) {
    getUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      UploadURL
    }
  }
`;

/**
 * @summary vinImeExcelFileUpload schema
 */
const BULK_UPLOAD = gql`
  mutation ($fileInfo: VinFileUploadInput!, $commonInput: CommonInputs!) {
    vinImeExcelFileUpload(fileInfo: $fileInfo, commonInput: $commonInput) {
      totalExcelDataRecords
      totalDuplicateRecords
      successfullyUploaded
      failedToUpload
      failedUploadList
    }
  }
`;

/**
 * @summary addVinImeiMap schema
 */
const ADD_VIN_IMEI_MAP = gql`
  mutation (
    $vin: String!
    $imei: String!
    $vehicleType: String!
    $vehicleModel: String!
    $fuelType: String!
    $oemId: Int!
  ) {
    addVinImeiMap(
      vin: $vin
      imei: $imei
      vehicleType: $vehicleType
      vehicleModel: $vehicleModel
      fuelType: $fuelType
      oemId: $oemId
    ) {
      code
      message
    }
  }
`;

/**
 * @summary addVinImeiMapping schema
 */
const ADD_VIN_IMEI_MAPPING = gql`
  mutation ($oemId: Int!, $vin: String!, $imei: String!, $engineNo: String!) {
    addVinImeiMapping(
      oemId: $oemId
      vin: $vin
      imei: $imei
      engineNo: $engineNo
    ) {
      code
      message
    }
  }
`;

const EXCEL_DOWNLOAD = gql`
  mutation ($oemId: Int!, $downloadFor: String!,$filterValue: String) {
    excelFileDownload(oemId: $oemId, downloadFor: $downloadFor,filterValue: $filterValue) {
      downloadUrl
    }
  }
`;

const getFeatureAccess = gql`
  query ($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const REMAP_IMEI = gql`
  mutation (
    $oemId: Int!
    $vin: String!
    $imei: String!
    $disAccosiate: Boolean
    $engineNo: String
  ) {
    vinImeiRemap(
      oemId: $oemId
      vin: $vin
      imei: $imei
      disAccosiate: $disAccosiate
      engineNo:$engineNo
    ) {
      code
      status
      message
    }
  }
`;

const CREATE_KYC_DETAILS = gql`
  mutation (
    $oemId: Int!
    $count: String!
    $vin: String!
    $imei: String
    $engineNumber: String!
    $yearOfmg: String!
    $vehicleMake: String!
    $vehicleModel: String!
    $fuelType: String!
    $customerID: String!
    $customerName: String!
    $customerMobile1: String!
    $retailInvoiceNumber: String
    $customerAddress: String!
    $customerEmail: String!
    $proofOfAddress: String!
    $proofOfIdentity: String
    $dealerCode: String!
    $dealerName: String!
    $dealerAddress: String!
    $dealerTown: String!
    $dealerContactName: String!
    $dealerContactNumber: String!
  ) {
    createKycDetails(
      oemId: $oemId
      count: $count
      vin: $vin
      imei: $imei
      engineNumber: $engineNumber
      yearOfmg: $yearOfmg
      vehicleMake: $vehicleMake
      vehicleModel: $vehicleModel
      fuelType: $fuelType
      customerID: $customerID
      customerName: $customerName
      customerMobile1: $customerMobile1
      retailInvoiceNumber: $retailInvoiceNumber
      customerAddress: $customerAddress
      customerEmail: $customerEmail
      proofOfAddress: $proofOfAddress
      proofOfIdentity: $proofOfIdentity
      dealerCode: $dealerCode
      dealerName: $dealerName
      dealerAddress: $dealerAddress
      dealerTown: $dealerTown
      dealerContactName: $dealerContactName
      dealerContactNumber: $dealerContactNumber
    ) {
      response {
        message
        count
        result
      }
    }
  }
`;

const ASSIGN_VIN_TO_DEALER = gql`
  mutation (
    $oemId: Int!
    $vin: String!
    $dealerCode: String!
    $dealerContactName: String
    $dealerContactNumber: String
  ) {
    assignVinToDealer(
      oemId: $oemId
      vin: $vin
      dealerCode: $dealerCode
      dealerContactName: $dealerContactName
      dealerContactNumber: $dealerContactNumber
    ) {
      code
      status
      message
    }
  }
`;

const GET_EOLT_DETAILS = gql`
  query ($vin: String!) {
    getEOLTDetailsByVin(vin: $vin) {
      code
      message
      data {
        vin
        imei
        lat
        long
        pluscode
        deviceTS
        serverTS
        packetType
        eoltCompletedOn
        firmwareVersion
      }
    }
  }
`;

const GET_ADDRESS = gql`
  query ($plusCode: String, $lat: String, $lng: String) {
    getAddressForPluscode(plusCode: $plusCode, lat: $lat, lng: $lng) {
      statusCode
      location
    }
  }
`;

const UPDATE_DEVICE_STATUS = gql`
  mutation ($vin: String!, $serverEpoch: String!) {
    updateDeviceStatus(vin: $vin, serverEpoch: $serverEpoch) {
      code
      message
    }
  }
`;

const GET_LATEST_DETAILS = gql`
  query ($Vin: String!) {
    getDeviceDetailsByVin(Vin: $Vin) {
      code
      message
      data {
        vin
        imei
        networkOperator
        boxCoverStatus
        address
        tcuBatlevel
        firmwareVersion
        serverTS

        latitude
        longitude
        gsmSignal
        ticketId
        ignitionTS
        vehBatVol
        fuelTemp
        adblueLevel
        odoMeter
        hourMeter
        dtc
        warningLight
        cleanerChokeIndicator
        lowOilPressure
        oilPassFilter
        dpfLampIndicator
        dpfActiveRegeneration
        dpfInhibitRegeneration
        massPercentage
        waterInFuelLevel
        torqueReduction
        speedReduction
        ecoMode
        engineRmp
        coolantTemp
        oilTemp
        fuelTemp
        gearIndicator
        latitude
        longitude
        createdByName
        createdById
        fileName
        helpButtonStatus
        packetType
        
      }
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  query ($bucketName: String!, $filename: String!) {
    getPublicDownloadURL(bucketName: $bucketName, filename: $filename)
  }
`;

const GET_EOLT_DETAILS_BY_VIN = gql`
  query ($oemId: Int!, $vin: String!) {
    getEoltReportDetails(oemId: $oemId, vin: $vin) {
      code
      message
      details {
        createdOn
        createdByName
        fileName
      }
    }
  }
`;

const GET_ALL_IMEIS = gql`
  query ($oemId: Int!) {
    getAllInventoryImei(oemId: $oemId) {
      code
      remarks
      data {
        imei
      }
    }
  }
`;

const GET_ALL_ASSIGN_VIN = gql`
  query ($oemId: Int!) {
    getRetailedVinByOem(oemId: $oemId) {
      code
      status
      message
      data {
        vin
      }
    }
  }
`;

const GET_ALL_ASSIGN_DEALER_CODE = gql`
  query ($oemId: Int!) {
    getDealerDetails(oemId: $oemId) {
      code
      message
      rows {
        dealerCode
      }
    }
  }
`;

const GET_DEALER_VIN_DETAILS_BY_VIN = gql`
  query ($vin: String!) {
    getDealerVinDetailsByVin(vin: $vin) {
      code
      status
      message
      data {
        dealerCode
        dealerContactName
        dealerContactNumber
      }
    }
  }
`;

const VIN_NO_CHNAGE = gql`
  mutation (
    $oemId: Int!
    $iccid: String!
    $imei: String!
    $oldVin: String!
    $newVin: String!
  ) {
    changeVinNumber(
      oemId: $oemId
      iccid: $iccid
      imei: $imei
      oldVin: $oldVin
      newVin:$newVin
    ) {
      code
      status
      message
    }
  }
`;

/**
 * @summary columns to show in response of bulk uploads
 */
const bulkUPloadColumns = [
  {
    name: "vin",
    lable: "VIN",
  },
  {
    name: "imei",
    lable: "IMEI",
  },
  {
    name: "vehicleType",
    lable: "Vehicle_Type",
  },
  {
    name: "vehicleModel",
    lable: "Vehicle_Model",
  },
  {
    name: "fuelType",
    lable: "Fuel_Type",
  },
  {
    name: "error",
    lable: "Error",
  },
];

/**
 * @summary columns to show All mapped Data
 */
const columns = [
  { key: 1, label: "Sr.No", minWidth: 100, align: "center" },
  {
    key: 2,
    label: "VIN",
    minWidth: 200,
    align: "center",
  },

  {
    key: 3,
    label: "IMEI",
    minWidth: 200,
    align: "center",
  },
  // {
  //   key: 4,
  //   label: "Retail Invoice Number",
  //   minWidth: 200,
  //   align: "center",
  // },
  {
    // key: 5,
    key: 4,
    label: "Device Model",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 6,
    key: 5,
    label: "Device  Status",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 7,
    key: 6,
    label: "VIN IMEI Mapped On",
    minWidth: 200,
    align: "center",
  },
  {
    key: 4,
    label: "Engine No.",
    minWidth: 150,
    align: "center",
  },
  {
    label: "VIN Dealer Map",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 9,
    key: 8,
    label: "Customer Name",
    minWidth: 350,
    align: "center",
  },
  {
    // key: 10,
    key: 9,
    label: "KYC Received On",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 11,
    key: 10,
    label: "KYC Approved On",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 12,
    key: 11,
    label: "KYC Rejected On",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 13,
    key: 12,
    label: "Last KYC Received",
    minWidth: 200,
    align: "center",
  },
  // {
  //   key: 14,
  //   label: "Vehicle Activation",
  //   minWidth: 200,
  //   align: "center",
  // },
  {
    // key: 14,
    key: 13,
    label: "Ticket Created",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 15,
    key: 14,
    label: "Status",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 16,
    key: 15,
    label: "KYC mode",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 17,
    key: 16,
    label: "Rejection Reason",
    minWidth: 800,
    align: "center",
  },
  {
    // key: 18,
    key: 17,
    label: "Sim Plan Type",
    minWidth: 350,
    align: "center",
  },
  {
    // key: 19,
    key: 18,
    label: "Sim Subscription Start",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 21,
    key: 19,
    label: "Sim Subscription End",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 22,
    key: 20,
    label: "Manual EOL",
    minWidth: 150,
    align: "center",
  },
  {
    // key: 22,
    key: 21,
    label: "EOLT Report",
    minWidth: 250,
    align: "center",
  },

  {
    // key: 23,
    key: 22,
    label: "Last Packet",
    minWidth: 200,
    align: "center",
  },
  {
    // key: 24,
    key: 23,
    label: "Manual KYC",
    minWidth: 200,
    align: "center",
  },
  {
    key: 24,
    label: "Vin No. change",
    minWidth: 200,
    align: "center",
  },
];

const statusOptions = [
  "All",
  "VIN-IMEI Mapped",
  // "VIN_EOLT_completed",
  "KYC_RECEIVED",
  "IMEI_NOT_MAPPED",
  "KYC_APPROVED",
  "KYC_REJECTED",
];
function VinImeMap(props) {
  const classes = useStyles();
  const [openAddVehicle, setAddVehicle] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [bulkUploadResponse, setBulkUploadResponse] = useState(null);
  const [vehicleType, setVehicleType] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAlldata] = useState();
  const [filterText, setFilterText] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [sortingOrder, setSortingOrder] = useState("ascending");
  const [isSort, setisSort] = React.useState(false);
  const [sortColumns, setSortColumn] = useState(null);
  const [filterStatus, setFilterStatus] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null);
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [imei, setImei] = useState(null);
  const [vin, setVin] = useState(null);
  const [engineNumber, setEngineNumber] = useState(null);
  const [yearOfmg, setYearOfmg] = useState(null);
  const [vehicleMake, setVehicleMake] = useState(null);
  const [vehicleModel, setVehicleModel] = useState(null);
  const [fuelType, setFuelType] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [customerMobile1, setCustomerMobile1] = useState(null);
  const [retailInvoiceNumber, setRetailInvoiceNumber] = useState(null);
  const [customerAddress, setCustomerAddress] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [proofOfAddress, setProofOfAddress] = useState(null);
  const [proofOfIdentity, setProofOfIdentity] = useState(null);
  const [dealerCode, setDealerCode] = useState(null);
  const [dealerName, setDealerName] = useState(null);
  const [dealerAddress, setDealerAddress] = useState(null);
  const [dealerTown, setDealerTown] = useState(null);
  const [dealerContactName, setDealerContactName] = useState(null);
  const [dealerContactNumber, setDealerContactNumber] = useState(null);
  const [uploadFile, setuploadFile] = useState(null);
  const [openEoltDetails, setEoltDetails] = useState(false);
  const [vinAssignedOn, setVinAssignedOn] = useState(null);
  const [postTS, setPostTS] = useState(null);
  const [deviceTS, setDeviceTS] = useState(null);
  const [serverTS, setServerTS] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [pluscode, setPlucCode] = useState(null);
  const [packetType, setPacketType] = useState(null);
  const [status, setStatus] = useState(null);
  const [eoltVin, setEoltVin] = useState(null);
  const [eoltImei, setEoltImei] = useState(null);
  const [show, setShow] = useState(true);
  const [POA, setPOA] = useState(true);
  const [POI, setPOI] = useState(true);
  const [editStatus, seteditStatus] = useState();
  const [searchInput, setSearchInput] = React.useState("");
  const [eoltSelReport, seteolSelRep] = useState("");
  const [eoltReport, setEoltReport] = useState(false);
  const [eoltReportDetails, setReportDetails] = useState([[]]);
  const [engineNo, setEngineNo] = useState(null);
  const [allImei, setAllimei] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [warningMsg, setMsg] = useState("");
  const [openAssignVinModal, setOpenAssignVinModal] = useState(false);
  const [assignVinList, setAssignVinList] = useState([]);
  const [assignVin, setAssignVin] = useState(null);
  const [assignDealerCodeList, setAssignDealerCodeList] = useState([]);
  const [assignDealerCode, setAssignDealerCode] = useState(null);
  const [assignDealerName, setAssignDealerName] = useState("");
  const [assignDealerContactNo, setAssignDealerContactNo] = useState("");
  const [selectedFilter, setSelFilter] = useState("");
  const [iccid,setIccid] = useState(null);
  const [vinNoChangeModal, setVinNoChangeModal] = useState(false);
  const [newVin, setNewVin] = useState(null);
  const [disableCloseButton, setDisabledClose] = useState(false); 

  let id;
  let history = useHistory();
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else if (parseInt(localStorage.getItem("featureId"))) {
    id = parseInt(localStorage.getItem("featureId"));
  } else {
    id = 10;
  }
  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    // setSearchInput("");
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    let searchVal = searchInput ? searchInput : selectedFilter;
    await getAllMappedData({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage, vinSearch: searchVal },
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    // setSearchInput("");
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    let searchVal = searchInput ? searchInput : selectedFilter;
    await getAllMappedData({
      variables: { oemId: props.oem, limit: event.target.value, offset: 0, vinSearch: searchVal },
    });
  };

  const hanldeImei = (e, sel) => {
    if (sel) {
      setImei(sel);
    }
  };

  const handleAssignVin = (event, value) => {
    setAssignDealerCode(null);
    setAssignDealerName("");
    setAssignDealerContactNo("");
    if (value) {
      setAssignVin(value);
      getDealerVinDetailsByVin({
        variables: {
          vin: value,
        },
      });
    }
  };

  const handleAssignDealer = (event, value) => {
    setAssignDealerName("");
    setAssignDealerContactNo("");
    if (value) {
      setAssignDealerCode(value);
    }
  };

  const handleRemap = () => {
    console.log(vin,';;;',imei);
    updateIMEI({
      variables: {
        vin: vin,
        imei: imei,
        oemId: props.oem,
        engineNo: engineNo,
      },
    });
  };

  /**
   * @summary This handles the modal of createKyc
   */
  const handleCreateKyc = (
    imei,
    vin,
    vehicleType,
    vehicleModel,
    fuelType,
    engineNumber,
    yearOfmg,
    vehicleMake,
    customerID,
    customerName,
    customerMobile1,
    retailInvoiceNumber,
    customerAddress,
    customerEmail,
    proofOfAddress,
    proofOfIdentity,
    dealerCode,
    dealerName,
    dealerAddress,
    dealerTown,
    dealerContactName,
    dealerContactNumber,
  ) => {
    setImei(imei);
    setVin(vin);
    setEngineNumber(engineNumber);
    setYearOfmg(yearOfmg);
    setVehicleMake(vehicleMake);
    setVehicleModel(vehicleModel);
    setFuelType(fuelType);
    setCustomerID(customerID);
    setCustomerName(customerName);
    setCustomerMobile1(customerMobile1);
    setRetailInvoiceNumber(retailInvoiceNumber);
    setCustomerAddress(customerAddress);
    setCustomerEmail(customerEmail);
    setProofOfAddress(proofOfAddress);
    setProofOfIdentity(proofOfIdentity);
    setDealerCode(dealerCode);
    setDealerName(dealerName);
    setDealerAddress(dealerAddress);
    setDealerTown(dealerTown);
    setDealerContactName(dealerContactName);
    setDealerContactNumber(dealerContactNumber);
    setOpen(true);
  };

  /**
   * @summary This handles the modal of createKyc
   */
  const handleKycClose = async () => {
    setOpen(false);
    setVin(null);
    setImei(null);
    setVehicleModel(null);
    setVehicleType(null);
    setFuelType(null);
    setPOA(true);
    setPOI(true);
  };

  /**
   * @summary This opens the form to add VIN and IMEI mapping
   */
  const handleAddVehicle = () => {
    setAddVehicle(true);
  };

  /**
   * @summary This opens the model to bulk upload
   */
  const handleBulkUploadModel = () => {
    setOpenBulkUpload(true);
  };

  /**
   * @summary This closes the model to bulk upload
   */
  const handleBulkUploadClose = () => {
    setOpenBulkUpload(false);
    setBulkUploadResponse(null);
  };

  /**
   * @summary This opens the model to assign vin to dealer modal
   */
  const handleAssignVinModel = () => {
    setOpenAssignVinModal(true);
    getAllAssignVin({
      variables: {
        oemId: props.oem,
      },
    });

    getAllAssignDealerCode({
      variables: {
        oemId: props.oem,
      },
    });
  };

  /**
   * @summary This closes the model to assign vehicle
   */
  const handleAssignVehicleClose = () => {
    setOpenAssignVinModal(false);
    setAssignVin(null);
    setAssignVinList([]);
    setAssignDealerCode(null);
    setAssignDealerCodeList([]);
    setAssignDealerName("");
    setAssignDealerContactNo("");
  };

  /**
   * @summary This closes the form to add VIN and IMEI map
   */
  const handleClose = () => {
    setAddVehicle(false);
    setVin(null);
    setImei(null);
    setVehicleModel(null);
    setVehicleType(null);
    setFuelType(null);
    setEdit(false);
    setOpen(false);
    setEngineNo(null);
  };

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };


  /**
   * @summary This call the api to add VIN and IMEI map
   */
  const addVMappingVinImei = async () => {
    let currentTime = Math.floor(Date.now() / 1000);
    console.log(vin + ";;;" + imei + ";;;;" + engineNo);
    if (
      vin == null ||
      imei == null ||
      engineNo == null ||
      vin == "" ||
      imei == "" ||
      engineNo == ""
    ) {
      props.setMessage("Please fill all the mandatory fields!");
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    } else {
      await newAddVinImeiMapping({
        variables: {
          oemId: props.oem,
          vin: vin,
          imei: imei,
          engineNo: engineNo,
        },
      });
      // }
    }
  };

  /**
   * @summary This uploads the file to bucket
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (file) {
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1,
        );
        if (fileExtension !== "xlsx") {
          props.setMessage("Only xlsx files are allowed");
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleSnackbarClose();
        } else {
          const response = await client2.mutate({
            mutation: GET_UPLOAD_URL,
            variables: {
              fileExtension,
            },
            errorPolicy: "all",
          });
          if (response.data && response.data.getPublicUploadURL) {
            const url = response.data.getPublicUploadURL.publicUploadURL;
            await axios.put(url, file, {
              headers: {
                'x-ms-blob-type': 'BlockBlob',
              },
              onUploadProgress: (data) => {
                props.setMessage(`Uploading....`);
                props.setSnackbar(true);
                handleSnackbarClose();
              },
            });
            setBulkUploadResponse(response);
          } else {
            props.setMessage("Something went wrong,Please Try again!");
            props.setNetworkError(true);
            props.setSnackbar(true);
            handleSnackbarClose();
          }
        }
      }
    } else {
      alert("No file selected");
    }
  };

  /**
   * @summary This adds the bulk upload file data
   */
  const submitBulkUpload = async () => {
    const { bucketName, filename } = bulkUploadResponse.data.getPublicUploadURL;

    let response;
    props.setUpload(true);
    response = await excelUpload({
      variables: {
        fileInfo: {
          uploadFor: "VinImeMap",
          bucketName,
          fileName: filename,
        },
        commonInput: {
          oemId: props.oem,
        },
      },
    });

    handleBulkUploadClose();
    setBulkUploadResponse(null);
  };

  /**
   * @summary Api call to add VIN and IMEI map
   */
  const [
    addVinImeiMapping,
    // { error: maperror, loading: mapLoading, data: response },
  ] = useMutation(ADD_VIN_IMEI_MAP, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (ressss) => {
      if (ressss.addVinImeiMap.code == 200) {
        props.setMessage("VIN IMEI Mapped Successfully");
        props.setNetworkError(true);
        props.setSnackbar(true);
      } else {
        props.setMessage(ressss.addVinImeiMap.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      handleSnackbarClose();
      handleClose();
      await getAllMappedData({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    },
  });

  /**
   * @summary Api call to add VIN and IMEI mapping
   */
  const [
    newAddVinImeiMapping,
    { error: maperror, loading: AddVehicleLoading, data: response },
  ] = useMutation(ADD_VIN_IMEI_MAPPING, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (ressss) => {
      if (ressss.addVinImeiMapping.code == 200) {
        props.setMessage("VIN IMEI Mapped Successfully");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
      } else {
        props.setMessage(ressss.addVinImeiMapping.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      handleSnackbarClose();
      // handleClose();
      await getAllMappedData({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    },
  });

  const [updateIMEI, { data: Response }] = useMutation(REMAP_IMEI, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: async (res) => {
      if (res?.vinImeiRemap?.code == 201) {
        props.setMessage("Details successfully updated");
        props.setNetworkError(true);
        props.setSnackbar(true);
        setSearchInput("");
        handleSnackbarClose();
        handleClose();
        setOpenWarning(false);
        await getAllMappedData({
          variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
        });
      } else {
        if (res?.vinImeiRemap?.status == "DISACCOSIATE") {
          props.setMessage(res.vinImeiRemap.message);
          setMsg(res.vinImeiRemap.message);
          setOpenWarning(true);
          return;
        }
        props.setMessage(res?.vinImeiRemap?.message || " Failed to remap");
        props.setNetworkError(true);
        props.setSnackbar(true);
        setOpenWarning(false);
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary Api call to add excel file
   */
  const [
    excelUpload,
    { loading: bulkLoading, error: bulkError, data: bulkResponse },
  ] = useMutation(BULK_UPLOAD, {
    fetchPolicy: "network-only",

    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setExcelError(`Message: ${networkError}`);
        props.closeDialog(false);
        props.setExcelData(null);
        props.setUpload(false);
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setExcelError(`Message: ${message}`);
          props.closeDialog(false);
          props.setExcelData(null);
          props.setUpload(true);
          props.setMessage("File headers are not Same as a given template!");
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: async (ressss) => {
      if (ressss?.vinImeExcelFileUpload?.totalExcelDataRecords == 0) {
        props.setNetworkError(true);
        props.setUpload(false);
        props.setMessage("Empty file is not allowed");
        props.setSnackbar(true);
      } else {
        await getAllMappedData({
          variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
        });
        props.setColumns(bulkUPloadColumns);
        props.setTitle("VINIMEI Uploads");
        props.setMessage(
          "The file data has been uploaded. Click on 'View' to view the result.",
        );
        props.setExcelData(ressss.vinImeExcelFileUpload);
        props.setNetworkError(false);
        props.setUpload(true);
        props.setSnackbar(true);
      }
    },
  });

  /**
   * @summary Api call to fetch all mapped Data with limit and offset
   */
  const [getAllMappedData, { error: error, loading: loading, data: Data }] =
    useLazyQuery(GET_ALL_MAPPED_DATA, {
      fetchPolicy: "network-only",
      // onError: ({ graphQLErrors, networkError }) => {
      //   if (networkError) {
      //     console.log(networkError);
      //     props.setMessage(`Internal Server Error`);
      //     props.setNetworkError(true);
      //     props.setSnackbar(true);
      //   }

      //   if (graphQLErrors) {
      //     graphQLErrors.forEach(({ message, locations, path }) => {
      //       console.log(message);
      //       props.setMessage(`Internal Server Error`);
      //       props.setNetworkError(true);
      //       props.setSnackbar(true);
      //     });
      //   }

      //   handleSnackbarClose();
      // },
      onCompleted: async (result) => {
        if (
          result.getAllVinImeiMapData &&
          result.getAllVinImeiMapData.mappedData
        ) {
          setRowsPerPage(rowsPerPage);
          setMappedData(result.getAllVinImeiMapData.mappedData.rows);
          setTotalCount(result.getAllVinImeiMapData.mappedData.totalCount);
          // setAlldata(result.getAllVinImeiMapData.mappedData.rows);
        }
      },
    });

  const [getMappedData] = useLazyQuery(GET_ALL_MAPPED_DATA, {
    fetchPolicy: "network-only",
    // onError: ({ graphQLErrors, networkError }) => {
    //   if (networkError) {
    //     console.log(networkError);
    //     props.setMessage(`Internal Server Error`);
    //     props.setNetworkError(true);
    //     props.setSnackbar(true);
    //   }

    //   if (graphQLErrors) {
    //     graphQLErrors.forEach(({ message, locations, path }) => {
    //       console.log(message);
    //       props.setMessage(`Internal Server Error`);
    //       props.setNetworkError(true);
    //       props.setSnackbar(true);
    //     });
    //   }

    //   handleSnackbarClose();
    // },
    onCompleted: async (result) => {
      if (
        result.getAllVinImeiMapData &&
        result.getAllVinImeiMapData.mappedData
      ) {
        setMappedData(result.getAllVinImeiMapData.mappedData.rows);
        setTotalCount(result.getAllVinImeiMapData.mappedData.totalCount);
        // setAlldata(result.getAllVinImeiMapData.mappedData.rows);
      }
    },
  });

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props)
    setPage(0);
    setNext(0);
    if (input) 
      {
        getAllMappedData({
          variables: {
            oemId: props.oem,
            limit: rowsPerPage,
            offset: 0,
            vinSearch: input.trim(),
          },
        });
        // setMappedData(filteredRows);
      } else {
        getAllMappedData({
          variables: {
            oemId: props.oem,
            limit: rowsPerPage,
            offset: page,
          },
        });
      }
  }, 1000), [props.oem,totalCount, rowsPerPage, page]);
 
  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    debounceSearchRender(input);
  };

  // /**
  //  * @summary this filters the data
  //  */
  // const handleSearchChange = (event) => {
  //   console.log(event.target.value, "value");
  //   // setFilterText(event.target.value);
  //   setSearchInput(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };

  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event) {
  //     // const filteredRows = allData.filter((row) => {
  //     //   const searchText = event.toLowerCase();
  //     //   return (
  //     //     (row.vin && row.vin.toLowerCase().includes(searchText)) ||
  //     //     (row.imei && row.imei.toLowerCase().includes(searchText)) ||
  //     //     (row.customerName &&
  //     //       row.customerName.toLowerCase().includes(searchText)) ||
  //     //     (row.customerID &&
  //     //       row.customerID.toLowerCase().includes(searchText)) ||
  //     //     (row.simPlanType &&
  //     //       row.simPlanType.toLowerCase().includes(searchText)) ||
  //     //     (row.status && row.status.toLowerCase().includes(searchText)) ||
  //     //     (row.retailInvoiceNumber &&
  //     //       row.retailInvoiceNumber.toLowerCase().includes(searchText))
  //     //   );
  //     // });
  //     getAllMappedData({
  //       variables: {
  //         oemId: props.oem,
  //         limit: totalCount,
  //         offset: 0,
  //         vinSearch: event.trim(),
  //       },
  //     });
  //     // setMappedData(filteredRows);
  //   } else {
  //     getAllMappedData({
  //       variables: {
  //         oemId: props.oem,
  //         limit: rowsPerPage,
  //         offset: page,
  //       },
  //     });
  //   }
  // });

  /**
   * @summary api call when search empty field is empty
   */
  const [fetchMapDataForSearch, { data: mapData }] = useLazyQuery(
    GET_ALL_MAPPED_DATA,
    {
      fetchPolicy: "network-only",
      onCompleted: async (result) => {
        if (
          result.getAllVinImeiMapData &&
          result.getAllVinImeiMapData.mappedData
        ) {
          setMappedData(result.getAllVinImeiMapData.mappedData.rows);
          setTotalCount(result.getAllVinImeiMapData.mappedData.totalCount);
        }
      },
    },
  );

  /**
   * @summary api call when status is All
   */
  const [fetchMapDataForStatus] = useLazyQuery(GET_ALL_MAPPED_DATA, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      if (
        result.getAllVinImeiMapData &&
        result.getAllVinImeiMapData.mappedData
      ) {
        setMappedData(result.getAllVinImeiMapData.mappedData.rows);
        setTotalCount(result.getAllVinImeiMapData.mappedData.totalCount);
      }
    },
  });

  /**
   * @summary this opens the filter menu
   */
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @summary handles the filter status
   */
  const handleStatusChange = async (status) => {
    setSearchInput("");
    setNext(0);
    setPage(0);
    
    if (status == "All") {
      setSelFilter("");
      await getAllMappedData({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: 0 },
      });
    } else {
      setSelFilter(status);
      getAllMappedData({
        variables: {
          oemId: props.oem,
          // limit: totalCount,
          limit:rowsPerPage,
          offset: 0,
          vinSearch: status.trim(),
        },
      });

      // const mappedData =
      //   status && allData.filter((row) => row.status === status);
      // setMappedData(mappedData);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    var token = localStorage.getItem("id_token");
    var decoded = token && jwt_decode(token);
    if (!token || currentTimestamp > decoded.exp) {
      localStorage.clear();
      history.push({
        pathname: "/login",
      });
    }
    else {
      if (props.oem) {
        getAllMappedData({
          variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
        });
      }
    }
    setSearchInput("");
    //removed previous snackbar state
    props.setSnackbar(false);
  }, [props.oem]);

  /**
   * @summary downloads the file
   */
  const [getDownloadUrl, { data: url, loading: downloading }] = useMutation(
    EXCEL_DOWNLOAD,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: async (result) => {
        const res = await axios({
          url: result.excelFileDownload.downloadUrl,
          method: "GET",
          headers: { Accept: "application/vnd.ms-excel" },
          responseType: "blob", // important
        });

        FileSaver.saveAs(new Blob([res.data]), "VIN_IMEI_MAP.xlsx");
      },
    },
  );

  /**
   * @summary This will call the api
   */
  const DownloadExcel = () => {
    getDownloadUrl({
      variables: {
        oemId: props.oem,
        downloadFor: "VIN_JOURNEY",
        filterValue: selectedFilter,
      },
    });
  };

  /**
   * @summary This opens the Edit Model
   */
  const openImeiEdit = (row) => {
    // getImei({
    //   variables: {
    //     oemId: props.oem,
    //   },
    // });
    setVin(row.vin);
    setImei(row.imei);
    setEngineNo(row.engineNumber);
    // setAddVehicle(true);
    seteditStatus(row.status);
    setEdit(true);

  };

  const handleEditEngineNo = (e) => {
    setEngineNo(e.target.value)
  }

  /**
   * @summary This opens vin number change modal
   */
  const openChangeVinNo = (row) => {
    setVin(row.vin);
    setImei(row.imei);
    setIccid(row.iccid);
    setVinNoChangeModal(true);
  };

  //sumbit vinno change
  const handleVinChangeSumbit = () => {
    console.log("change vin number api call");
    console.log(vin,";;;",newVin,";;;",imei,";;;",iccid);
    setDisabledClose(true);
    changeVinNumber({
      variables: {
        oldVin: vin,
        imei: imei,
        oemId: props.oem,
        iccid: iccid,
        newVin: newVin
      },
    });
  };

  /**
   * @summary This closes vin number change modal
   */
  const handleVinChangeClose = () => {
    setVin(null);
    setImei(null);
    setIccid(null);
    setNewVin(null);
    setVinNoChangeModal(false);
  };

  const [changeVinNumber, { loading: changeVinNumberLoading, data: changeResponse }] = useMutation(VIN_NO_CHNAGE, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
      setDisabledClose(false);
    },
    onCompleted: async (res) => {
      if (res?.changeVinNumber?.code == 200) {
        props.setMessage(res.changeVinNumber.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
        handleVinChangeClose();
        setOpenWarning(false);
        await getAllMappedData({
          variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
        });
      } else {
        props.setMessage(res.changeVinNumber.message);
        setMsg(res.changeVinNumber.message);
        setOpenWarning(true);
        props.setNetworkError(true);
        props.setSnackbar(true);
        setOpenWarning(false);
        handleSnackbarClose();
      }
      setDisabledClose(false);
    },
  });

  const [getAccess, { data: access }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  /**
   * @summary api call to set kyc Details
   */
  const [
    createKyc,
    { error: createError, loading: createloading, data: createData },
  ] = useMutation(CREATE_KYC_DETAILS, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
      handleSnackbarClose();
    },
    onCompleted: async (result) => {
      console.log(result, "result");
      if (result.createKycDetails.response[0].result == 1) {
        props.setMessage("Succesfully Created the Kyc details");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
        setSearchInput("");
        await getAllMappedData({
          variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
        });
      } else {
        if (result.createKycDetails.response[0].message) {
          props.setMessage(result.createKycDetails.response[0].message);
          props.setNetworkError(true);
          props.setSnackbar(true);
          // handleClose();
        }
      }
      handleSnackbarClose();
    },
  });

  /**
   * @summary api  call to upload file for proof Of Address
   */
  const [uploadURLForAddress] = useMutation(UPLOAD_URL, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
      handleSnackbarClose();
    },
    onCompleted: async (response) => {
      console.log(response, "response");

      if (response && response.getUploadURL.UploadURL) {
        await axios.put(response.getUploadURL.UploadURL, uploadFile, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            let percentage = Math.round((100 * data.loaded) / data.total);

            props.setSnackbar(false);
            // handleSnackbarClose();
          },
        });
        setProofOfAddress(response.getUploadURL.filename);
        setPOA(false);
        setuploadFile(null);
      } else {
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUploadProofOfAddress = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1,
        );
        // setuploadUrl(fileExtension);
        props.setUpload(false);
        props.setNetworkError(false);
        props.setMessage(`Uploading....`);
        props.setSnackbar(true);
        await uploadURLForAddress({
          variables: {
            fileExtension,
          },
        });
        // await getUploadURL(file);
        // console.log(uploadUrl, "uploadUrl");

        // let url = await getUploadURL(fileExtension);
        // console.log(url, "url");
        // const response = await client3.mutate({
        //   mutation: UPLOAD_URL,
        //   variables: {
        //     fileExtension,
        //   },
        //   errorPolicy: "all",
        // });
        // console.log(uploadUrlData, "uploadUrlData");
        // if (false) {
        //   // console.log("response----", uploadUrlData);
        //   // const url =
        //   //   getUploadURL &&
        //   //   getUploadURL.getUploadURL &&
        //   //   getUploadURL.getUploadURL.UploadURL;
        //   // await axios.put(url, file, {
        //   //   onUploadProgress: (data) => {
        //   //     //Set the progress value to show the progress bar
        //   //     let percentage = Math.round((100 * data.loaded) / data.total);
        //   //     props.setMessage(`Uploading....`);
        //   //     props.setSnackbar(true);
        //   //     handleClose();
        //   //   },
        //   // });
        //   setProofOfAddress(response.data.getUploadURL.filename);
        // }
        // else {
        //   props.setMessage("Something went wrong,Please Try again!");
        //   props.setSnackbar(true);
        //   handleClose();
        // }
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  /**
   * @summary api call to upload the file for ProofOfIdentity
   */
  const [uploadURLForIdentity] = useMutation(UPLOAD_URL, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
      handleSnackbarClose();
    },
    onCompleted: async (response) => {
      console.log(response, "response");

      if (response && response.getUploadURL.UploadURL) {
        console.log(
          response.getUploadURL.UploadURL,
          "response.getUploadURL.UploadURL",
        );

        await axios.put(response.getUploadURL.UploadURL, uploadFile, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            let percentage = Math.round((100 * data.loaded) / data.total);

            props.setSnackbar(false);
            // handleSnackbarClose();
          },
        });
        setProofOfIdentity(response.getUploadURL.filename);
        // setuploadUrl(response.getUploadURL.UploadURL);
        setPOI(false);
        setuploadFile(null);
      } else {
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary uploads the file for ProofOfIdentity
   */
  const onUploadProofOfIdentity = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);

      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1,
        );
        props.setUpload(false);
        props.setNetworkError(false);
        props.setMessage(`Uploading....`);
        props.setSnackbar(true);
        setuploadFile(file);
        uploadURLForIdentity({
          variables: {
            fileExtension,
          },
        });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  const handleKycSubmit = () => {
    if (
      imei == null ||
      vin == null ||
      engineNumber == null ||
      yearOfmg == null ||
      vehicleMake == null ||
      vehicleModel == null ||
      fuelType == null ||
      customerID == null ||
      customerName == null ||
      customerMobile1 == null ||
      customerAddress == null ||
      customerEmail == null ||
      proofOfAddress == null ||
      proofOfIdentity == null ||
      dealerCode == null ||
      dealerName == null ||
      dealerAddress == null ||
      dealerTown == null ||
      dealerContactName == null ||
      dealerContactNumber == null ||
      imei == "" ||
      vin == "" ||
      engineNumber == "" ||
      yearOfmg == "" ||
      vehicleMake == "" ||
      vehicleModel == "" ||
      fuelType == "" ||
      customerID == "" ||
      customerName == "" ||
      customerMobile1 == "" ||
      customerAddress == "" ||
      customerEmail == "" ||
      dealerCode == "" ||
      dealerName == "" ||
      dealerAddress == "" ||
      dealerTown == "" ||
      dealerContactName == "" ||
      dealerContactNumber == ""
    ) {
      props.setMessage("Please fill all the mandatory fields!");
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    } else {
      createKyc({
        variables: {
          oemId: props.oem,
          count: "1",
          vin: vin,
          imei: imei,
          engineNumber: engineNumber,
          yearOfmg: yearOfmg,
          vehicleMake: vehicleMake,
          vehicleModel: vehicleModel,
          fuelType: fuelType,
          customerID: customerID,
          customerName: customerName,
          customerMobile1: customerMobile1,
          retailInvoiceNumber: retailInvoiceNumber,
          customerAddress: customerAddress,
          customerEmail: customerEmail,
          proofOfAddress: proofOfAddress,
          proofOfIdentity: proofOfIdentity,
          dealerCode: dealerCode,
          dealerName: dealerName,
          dealerAddress: dealerAddress,
          dealerTown: dealerTown,
          dealerContactName: dealerContactName,
          dealerContactNumber: dealerContactNumber,
        },
      });
    }
  };

  /**
   * @summary api call to get eolt details
   */
  const [getEOltDetails] = useLazyQuery(GET_EOLT_DETAILS, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
      handleSnackbarClose();
    },
  });

  /**
   * @summary opens the eolt detial models with all the data
   */
  const handleEoltFistTS = async (row) => {
    await getEOltDetails({
      variables: {
        vin: row.vin,
      },
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        console.log(res);
        setEoltVin(row.vin);
        setEoltImei(row.imei);
        let createdDate = moment
          .unix(row.createdOn)
          .format("DD-MM-YYYY HH:mm:ss");
        console.log(createdDate, "createdDate");
        setVinAssignedOn(createdDate);
        setStatus(row.status);
        if (res.getEOLTDetailsByVin.data) {
          let eoltTs = res.getEOLTDetailsByVin.data.eoltCompletedOn
            ? moment
              .unix(res.getEOLTDetailsByVin.data.eoltCompletedOn)
              .format("DD-MM-YYYY HH:mm:ss")
            : null;
          setPostTS(eoltTs);
          setServerTS(eoltTs);
          let deviceTs = res.getEOLTDetailsByVin.data.deviceTS
            ? moment
              .unix(res.getEOLTDetailsByVin.data.deviceTS)
              .format("DD-MM-YYYY HH:mm:ss")
            : null;
          setDeviceTS(deviceTs);
          setLat(res.getEOLTDetailsByVin.data.lat);
          setLong(res.getEOLTDetailsByVin.data.long);
          if (res.getEOLTDetailsByVin.data.pluscode) {
            getAddress({
              variables: {
                plusCode: res.getEOLTDetailsByVin.data.pluscode,
              },
              onCompleted: (res) => {
                setPlucCode(res.getAddressForPluscode.location);
              },
            });
          }
          // setPlucCode(res.getEOLTDetailsByVin.data.pluscode);
          setPacketType(res.getEOLTDetailsByVin.data.packetType);
        } else {
          setPostTS(null);
          setDeviceTS(null);
          setLat(null);
          setLong(null);
          setPlucCode(null);
          setPacketType(null);
          setServerTS(null);
        }
      },
    });
    setEoltDetails(true);
  };

  const handleEoltReport = async (row) => {
    setEoltReport(true);
    getEoltReportsByVIN({
      variables: {
        oemId: props.oem,
        vin: row.vin,
      },
      onCompleted: (res) => {
        if (res?.getEoltReportDetails?.code == 200) {
          setReportDetails(res.getEoltReportDetails.details);
          // const serverTS = res?.getEoltReportDetails?.details[0]?.createdOn
          //   ? moment
          //       .unix(res?.getEoltReportDetails?.details[0].createdOn)
          //       .format("DD-MM-YYYY HH:mm:ss")
          //   : null;
          // setReportDetails([
          //   [
          //     serverTS || "NA",
          //     res?.getEoltReportDetails?.details[0]?.createdByName || "NA",
          //     row.eoltReportName || "NA",
          //   ],
          // ]);
        }
      },
    });
  };

  const handleDownloadReport = async (rep) => {
    console.log(eoltSelReport, "eoltSelReport");
    const response = await client2.query({
      query: GET_DOWNLOAD_URL,
      variables: {
        // bucketName: "sml-files",
        bucketName:  process.env.REACT_APP_AZURE_DEVICEUPLOAD_CONTAINER,
        filename: rep,
      },
      errorPolicy: "all",
    });
    console.log(response, "res");
    if (response?.data?.getPublicDownloadURL) {
      // window.open(response.data.getPublicDownloadURL);

      const res = await axios({
        url: response.data.getPublicDownloadURL,
        method: "GET",
        // headers: { Accept: "application/vnd.ms-excel" },
        responseType: "blob",
      });

      FileSaver.saveAs(new Blob([res.data]), rep);

    } else {
      props.setMessage("Failed to download");
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    }
  };

  const closeReport = () => {
    setEoltReport(false);
  };

  const closeEoltDetails = () => {
    setEoltDetails(false);
  };

  /**
   * @summary api call to get latest call
   */
  const isEOLTCompleted = async (imei) => {
    let token = localStorage.getItem("id_token");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_OEM_EOLT_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        imeiList: [`t_${imei}`],
      },
    };

    let res = axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data), "tele");
        return response.data;
      })
      .catch((error) => {
        console.log(error, "error");
        return error;
      });
    return res;
  };
  /**
   * @summary api to get address on telematics check
   */
  const [getAddress, { error: addressError }] = useLazyQuery(GET_ADDRESS, {
    fetchPolicy: "network-only",
  });
  const [updateDeviceStatus] = useMutation(UPDATE_DEVICE_STATUS, {
    errorPolicy: "all",
    onCompleted: async () => {
      setSearchInput("");
      await getMappedData({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    },
  });

  const [getLatestpacket] = useLazyQuery(GET_LATEST_DETAILS, {
    fetchPolicy: "network-only",
  });

  const [getEoltReportsByVIN] = useLazyQuery(GET_EOLT_DETAILS_BY_VIN, {
    fetchPolicy: "network-only",
  });

  /**
   * @summary this handles the latest eolt details
   */
  const handleEoltLatest = async (row) => {
    setEoltVin(row.vin);
    setEoltImei(row.imei);
    let createdDate = moment.unix(row.createdOn).format("DD-MM-YYYY HH:mm:ss");
    console.log(createdDate, "createdDate");
    setVinAssignedOn(createdDate);
    setStatus(
      row.eoltCompletedOn ? "VIN_EOLT_completed" : "EOLT_NOT_completed",
    );
    let eoltTS = row.eoltCompletedOn
      ? moment.unix(row.eoltCompletedOn).format("DD-MM-YYYY HH:mm:ss")
      : null;
    setPostTS(eoltTS);
    await getLatestpacket({
      variables: {
        Vin: row.vin,
      },
      onCompleted: async (res) => {
        if (
          res.getDeviceDetailsByVin.code == 200 &&
          res.getDeviceDetailsByVin.data
        ) {
          let detail = res.getDeviceDetailsByVin.data;

          const deviceTS = detail.deviceTS
            ? moment.unix(detail.deviceTS).format("DD-MM-YYYY HH:mm:ss")
            : null;

          const serverTS = moment
            .unix(detail.serverTS)
            .format("DD-MM-YYYY HH:mm:ss");
          setServerTS(serverTS);
          setDeviceTS(serverTS);
          setLat(detail.latitude);
          setLong(detail.longitude);
          setPacketType(detail.packetType);
          if (detail.latitude && detail.longitude) {
            getAddress({
              variables: {
                lat: detail.latitude,
                lng: detail.longitude,
              },
              onCompleted: (res) => {
                setPlucCode(res.getAddressForPluscode.location);
              },
            });
          }
          try {
            await updateDeviceStatus({
              variables: {
                vin: row.vin,
                serverEpoch: detail.serverTS.toString(),
              },
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          setServerTS(null);
          setPostTS(null);
          setDeviceTS(null);
          setLat(null);
          setLong(null);
          setPlucCode(null);
          setPacketType(null);
        }
      },
    });

    setEoltDetails(true);
  };

  const [getImei] = useLazyQuery(GET_ALL_IMEIS, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
      handleSnackbarClose();
    },
    onCompleted: (res) => {
      if (res.getAllInventoryImei.code == 200) {
        let data = res.getAllInventoryImei.data.map((imei) => imei.imei);
        setAllimei(data);
      }
    },
  });

  /**
   * @summary Api call to getAll vin which can be assigned to dealer
   */
  const [getAllAssignVin] = useLazyQuery(GET_ALL_ASSIGN_VIN, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
      handleSnackbarClose();
    },
    onCompleted: (res) => {
      if (res.getRetailedVinByOem.code == 200) {
        let data = res.getRetailedVinByOem.data.map((value) => value.vin);
        setAssignVinList(data);
      } else {
        props.setMessage(`Message: ${res.getRetailedVinByOem.message}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary Api call to getAll dealer code of oem
   */
  const [getAllAssignDealerCode] = useLazyQuery(GET_ALL_ASSIGN_DEALER_CODE, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleClose();
      handleSnackbarClose();
    },
    onCompleted: (res) => {
      let data = [];
      if (res.getDealerDetails.code == 200) {
        let resArr = res.getDealerDetails.rows;
        for (let i = 0; i < resArr.length; i++) {
          if (
            resArr[i].dealerCode != null ||
            resArr[i].dealerCode != undefined
          ) {
            data.push(resArr[i].dealerCode);
          }
        }
        setAssignDealerCodeList(data);
      } else {
        props.setMessage(`Message: ${res.getDealerDetails.message}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary Api call to getAll dealer vin details by vin
   */
  const [getDealerVinDetailsByVin] = useLazyQuery(
    GET_DEALER_VIN_DETAILS_BY_VIN,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
        handleClose();
        handleSnackbarClose();
      },
      onCompleted: (res) => {
        if (
          res &&
          res.getDealerVinDetailsByVin &&
          res.getDealerVinDetailsByVin.code == 200
        ) {
          let { dealerCode, dealerContactName, dealerContactNumber } =
            res.getDealerVinDetailsByVin.data;
          setAssignDealerCode(dealerCode);
          setAssignDealerName(dealerContactName);
          setAssignDealerContactNo(dealerContactNumber);
        } else {
          props.setMessage(`Message: ${res.getDealerVinDetailsByVin.message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
          handleSnackbarClose();
        }
      },
    },
  );
  /**
   * @summary this function is to submit
   */
  const onSubmitAssignVehicleToDealer = () => {
    if (
      !assignVin ||
      !assignDealerCode ||
      !assignDealerName ||
      !assignDealerContactNo
    ) {
      props.setMessage("Please fill all the mandatory fields!");
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleSnackbarClose();
    } else {
      assignVinToDealer({
        variables: {
          oemId: props.oem,
          vin: assignVin,
          dealerCode: assignDealerCode,
          dealerContactName: assignDealerName,
          dealerContactNumber: assignDealerContactNo,
        },
      });
    }
  };
  /**
   * @summary Api call to assign vehicle to dealer and edit dealer code
   */
  const [assignVinToDealer, { data: assignVinToDealerResponse }] = useMutation(
    ASSIGN_VIN_TO_DEALER,
    {
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
        handleSnackbarClose();
      },
      onCompleted: async (res) => {
        if (res?.assignVinToDealer?.code == 200) {
          props.setMessage(res.assignVinToDealer.message);
          props.setNetworkError(true);
          props.setSnackbar(true);
          await getDealerVinDetailsByVin({
            variables: {
              vin: assignVin,
            },
          });
          handleSnackbarClose();
          handleAssignVehicleClose();
          setOpenWarning(false);
        } else {
          props.setMessage(
            res?.assignVinToDealer?.message || "Failed to assign vehicle!",
          );
          props.setNetworkError(true);
          props.setSnackbar(true);
          setOpenWarning(false);
          handleSnackbarClose();
        }
      },
    },
  );

  const closeWarning = () => {
    setOpenWarning(false);
  };

  const confirmRemap = () => {
    updateIMEI({
      variables: {
        vin: vin,
        imei: imei,
        disAccosiate: true,
        oemId: props.oem,
        engineNo: engineNo,
      },
    });
  };

  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });
  }, [props.oem]);

  return (
    <React.Fragment>
      <CustomDialogBox
        open={openWarning}
        content={warningMsg}
        negativeResponseHandler={closeWarning}
        negativeResponseButtonText="NO"
        positiveResponseHandle={confirmRemap}
        positiveResponseButtonText="YES"
      />
      <EoltLogsReport
        open={eoltReport}
        eoltReportDetails={eoltReportDetails}
        handleDownloadReport={handleDownloadReport}
        closeReport={closeReport}
        seteolSelRep={seteolSelRep}
      />
      <AddVehicle
        open={openAddVehicle}
        handleClose={handleClose}
        setVin={setVin}
        vin={vin}
        imei={imei}
        engineNo={engineNo}
        setImei={setImei}
        setEngineNo={setEngineNo}
        addMapping={addVMappingVinImei}
        loading={AddVehicleLoading}
      />
      <RemapVinImei
        open={edit}
        closeEditModel={handleClose}
        vin={vin}
        imei={imei}
        engineNo={engineNo}
        handleRemap={handleRemap}
        handleImei={hanldeImei}
        // allImei={allImei}
        status={editStatus}
        handleEditEngineNo={handleEditEngineNo}
        setImei={setImei}
      />

      <VinBulkUploads
        open={openBulkUpload}
        handleClose={handleBulkUploadClose}
        onUpload={onUpload}
        submitBulkUpload={submitBulkUpload}
        bulkUploadResponse={bulkUploadResponse}
        snackbar={props.open}
      />
      <CreateKyc
        open={open}
        show={show}
        handleKycClose={handleKycClose}
        imei={imei}
        vin={vin}
        engineNumber={engineNumber}
        yearOfmg={yearOfmg}
        vehicleMake={vehicleMake}
        vehicleModel={vehicleModel}
        fuelType={fuelType}
        customerID={customerID}
        customerName={customerName}
        customerMobile1={customerMobile1}
        retailInvoiceNumber={retailInvoiceNumber}
        customerAddress={customerAddress}
        customerEmail={customerEmail}
        proofOfAddress={proofOfAddress}
        POA={POA}
        setPOA={setPOA}
        proofOfIdentity={proofOfIdentity}
        POI={POI}
        setPOI={setPOI}
        dealerCode={dealerCode}
        dealerName={dealerName}
        dealerAddress={dealerAddress}
        dealerTown={dealerTown}
        dealerContactName={dealerContactName}
        dealerContactNumber={dealerContactNumber}
        setImei={setImei}
        setVin={setVin}
        setEngineNumber={setEngineNumber}
        setYearOfmg={setYearOfmg}
        setVehicleMake={setVehicleMake}
        setVehicleModel={setVehicleModel}
        setFuelType={setFuelType}
        setCustomerID={setCustomerID}
        setCustomerName={setCustomerName}
        setCustomerMobile1={setCustomerMobile1}
        setRetailInvoiceNumber={setRetailInvoiceNumber}
        setCustomerAddress={setCustomerAddress}
        setCustomerEmail={setCustomerEmail}
        setProofOfAddress={setProofOfAddress}
        setProofOfIdentity={setProofOfIdentity}
        setDealerCode={setDealerCode}
        setDealerName={setDealerName}
        setDealerAddress={setDealerAddress}
        setDealerTown={setDealerTown}
        setDealerContactName={setDealerContactName}
        setDealerContactNumber={setDealerContactNumber}
        onUploadProofOfAddress={onUploadProofOfAddress}
        onUploadProofOfIdentity={onUploadProofOfIdentity}
        handleKycSubmit={handleKycSubmit}
        createloading={createloading}
        snackbarOpen={props.open}
      />
      <EOLTDetails
        open={openEoltDetails}
        handleEoltFistTS={closeEoltDetails}
        vinAssignedOn={vinAssignedOn}
        vin={eoltVin}
        imei={eoltImei}
        postTS={postTS}
        deviceTS={deviceTS}
        serverTS={serverTS}
        lat={lat}
        long={long}
        plusCode={pluscode}
        packetType={packetType}
        status={status}
      />
      <AssignVinToDealer
        open={openAssignVinModal}
        handleClose={handleAssignVehicleClose}
        // handleClose={handleClose}
        assignVin={assignVin}
        assignVinList={assignVinList}
        handleAssignVin={handleAssignVin}
        assignDealerCode={assignDealerCode}
        assignDealerCodeList={assignDealerCodeList}
        handleAssignDealer={handleAssignDealer}
        assignDealerName={assignDealerName}
        setAssignDealerName={setAssignDealerName}
        assignDealerContactNo={assignDealerContactNo}
        setAssignDealerContactNo={setAssignDealerContactNo}
        handleAssignVehicleToDealer={onSubmitAssignVehicleToDealer}
        snackbar={props.open}
      />
      <VinNoChange
        open={vinNoChangeModal}
        closeVinChangeModel={handleVinChangeClose}
        vin={vin}
        imei={imei}
        iccid={iccid}
        handleVinChangeSumbit={handleVinChangeSumbit}   
        setNewVin = {setNewVin} 
        newVin = {newVin}  
        changeVinNumberLoading = {changeVinNumberLoading} 
        disableCloseButton = {disableCloseButton}
        setImei = {setImei}
        setIccid = {setIccid}
      />
      {
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={classes.title}>
                {/* <Typography variant="h4" >
                  VIN Journey
                </Typography> */}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  style={{
                    backgroundColor: "#FFFFFF",
                    paddingTop: "40px",
                  }}
                // spacing={4}
                >
                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingLeft: "60px",
                          paddingBottom: "8px",
                          paddingTop: "16px",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={10}>
                            <TextField
                              placeholder="Search Number"
                              variant="outlined"
                              onChange={(e) => handleSearchChange(e)}
                              style={{
                                width: "100%",
                                backgroundColor: "#F3F3F3 ",
                              }}
                              size="small"
                              value={searchInput}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon
                                      style={{ paddingLeft: "3px" }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.textfield}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              onClick={handleFilterClick}
                              // disabled={totalCount == 0}
                            >
                              <FilterListIcon style={{ fill: "#57B793" }} />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => setAnchorEl(null)}
                            >
                              {statusOptions.map((status) => (
                                <MenuItem
                                  key={status}
                                  onClick={() => handleStatusChange(status)}
                                >
                                  {status}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Grid>
                          <Grid item xs={1}>
                            {downloading ? (
                              <CircularProgress
                                size={30}
                                style={{ color: "#57B793" }}
                              />
                            ) : (
                              <IconButton
                                title="Download Excel"
                                aria-label="Download Excel"
                                onClick={DownloadExcel}
                                disabled={totalCount == 0}
                              >
                                <CloudDownloadIcon
                                  style={{ fill: "#57B793", fontSize: 30 }}
                                />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                        {/* </Grid> */}
                      </Grid>
                      <Grid item xs={6} style={{ padding: "16px" }}>
                        {createPermission && (
                          <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item xs={4}>
                              <ColorButton onClick={handleAddVehicle}>
                                <Typography className={classes.buttonText}>
                                  Map VIN
                                </Typography>
                              </ColorButton>
                            </Grid>
                            <Grid item xs={4}>
                              {/* Currently bulk upload vin is not a feature that is why removing*/}
                              {/* <ColorButton onClick={handleBulkUploadModel}>
                                <Typography>Bulk Upload</Typography>
                              </ColorButton> */}
                              <ColorButton onClick={handleAssignVinModel}>
                                <Typography>Assign VIN</Typography>
                              </ColorButton>
                            </Grid>
                            <Grid item xs={4}>
                              {/* <ColorSampleButton
                                href={VinImeMapSample}
                                // className={classes.sampleButton}
                                download="Vin IMEI Mapping Sample"
                              >
                                <Typography style={{ color: "white" }}>
                                  Sample File
                                </Typography>
                              </ColorSampleButton> */}
                            </Grid>
                            {/* <Grid item> */}
                            {/* <IconButton
                          title="Download Excel"
                          aria-label="Download Excel"
                          onClick={DownloadExcel}
                        >
                          <CloudDownloadIcon style={{ fill: "#57B793" }} />
                        </IconButton> */}
                            {/* </Grid> */}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid >
                  <Grid
                    xs={12}
                    style={{ paddingTop: "0px", maxHeight: "60vh" }}
                  >
                    {loading ? (
                      <CustomCircularProgress />
                    ) : mappedData.length > 0 ? (
                      <>
                        <Paper>
                          <TableContainer
                            sx={{
                              overflow: "scroll",
                              maxHeight: "calc(100vh - 280px)",
                            }}
                          >
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {columns.map((columns) => (
                                    <TableCell
                                      align="center"
                                      style={{
                                        minWidth: columns.minWidth,
                                        font: "normal normal 600 15px/20px Nunito Sans",
                                        backgroundColor: "#faf8f7",
                                      }}
                                      className={classes.tableHead}
                                    >
                                      {columns.label}
                                    </TableCell>
                                  ))}
                                  {updatePermission || deletePermission ? (
                                    <TableCell
                                      align="center"
                                      style={{
                                        font: "normal normal 600 15px/20px Nunito Sans",
                                        letterSpacing: "0px",
                                        color: "#212121",
                                        borderRightWidth: 1,
                                        borderColor: "#E8E8E8",
                                        borderTop: "1px solid #E8E8E8",
                                        backgroundColor: "#faf8f7",
                                        padding: "8px",
                                        minWidth: 80,
                                      }}
                                    >
                                      Action
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {mappedData &&
                                  mappedData.map((row, index) => {
                                    let createdDate =
                                      row.createdOn &&
                                      moment
                                        .unix(row.createdOn)
                                        .format("DD-MM-YYYY HH:mm:ss");
                                    let kycApprovedDate = row.kycApprovedDate
                                      ? moment
                                        .unix(row.kycApprovedDate)
                                        .format("DD-MM-YYYY HH:mm:ss")
                                      : "";
                                    let kycRejectedDate = row.kycRejectedDate
                                      ? moment
                                        .unix(row.kycRejectedDate)
                                        .format("DD-MM-YYYY HH:mm:ss")
                                      : "";
                                    let lastKycReceivedDate =
                                      row.lastKycReceivedDate
                                        ? moment
                                          .unix(row.lastKycReceivedDate)
                                          .format("DD-MM-YYYY HH:mm:ss")
                                        : "";
                                    let vehicleActivationDate =
                                      row.vehicleActivationDate
                                        ? moment
                                          .unix(
                                            row.vehicleActivationDate * 1000,
                                          )
                                          .format("DD-MM-YYYY HH:mm:ss")
                                        : "";

                                    let kycReceivedDate;

                                    if (
                                      moment(
                                        row.kycReceivedDate,
                                        "YYYY-MM-DD HH:mm:ss",
                                        true,
                                      ).isValid()
                                    ) {
                                      kycReceivedDate = moment(
                                        row.kycReceivedDate,
                                      ).format("DD-MM-YYYY HH:mm:ss");
                                    } else if (
                                      moment(
                                        row.kycReceivedDate,
                                        "X",
                                        true,
                                      ).isValid()
                                    ) {
                                      kycReceivedDate = moment
                                        .unix(row.kycReceivedDate)
                                        .format("DD-MM-YYYY HH:mm:ss");
                                    } else {
                                      kycReceivedDate = null;
                                    }

                                    let simSubscriptionStartDate =
                                      row.simSubscriptionStartDate
                                        ? moment
                                          .unix(
                                            row.simSubscriptionStartDate /
                                            1000,
                                          )
                                          .format("DD-MM-YYYY HH:mm:ss")
                                        : "";

                                    let simSubscriptionEndDate =
                                      row.simSubscriptionEndDate
                                        ? moment
                                          .unix(
                                            row.simSubscriptionEndDate / 1000,
                                          )
                                          .format("DD-MM-YYYY HH:mm:ss")
                                        : "";
                                    let eoltTS = row.EOLTFirstTS
                                      ? moment
                                        .unix(row.EOLTFirstTS)
                                        .format("DD-MM-YYYY HH:mm:ss")
                                      : "";
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {next + (index + 1)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.vin}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.imei ? row.imei : "NA"}
                                        </TableCell>
                                        {/* <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.retailInvoiceNumber
                                          ? row.retailInvoiceNumber //Here Change
                                          : "NA"}
                                      </TableCell> */}
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.deviceModel
                                            ? row.deviceModel
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.status == "IMEI_NOT_MAPPED"
                                            ? "NA"
                                            : row.deviceStatus
                                              ? row.deviceStatus
                                              : "NA"
                                          }
                                        </TableCell >
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {createdDate ? createdDate : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.engineNumber
                                            ? row.engineNumber
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.vinDealerCode
                                            ? row.vinDealerCode
                                            : "NA"}
                                        </TableCell >
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.customerName
                                            ? row.customerName
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {kycReceivedDate
                                            ? kycReceivedDate
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {kycApprovedDate
                                            ? kycApprovedDate
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {kycRejectedDate
                                            ? kycRejectedDate
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {lastKycReceivedDate
                                            ? lastKycReceivedDate
                                            : "NA"}
                                        </TableCell>
                                        {/* <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {vehicleActivationDate?vehicleActivationDate:"NA"}
                                      </TableCell> */}

                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.ticketCreated
                                            ? row.ticketCreated
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.status ? row.status : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.kycMode ? row.kycMode : "NA"}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.rejectionReason
                                            ? row.rejectionReason
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.simPlanType
                                            ? row.simPlanType
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {simSubscriptionStartDate
                                            ? simSubscriptionStartDate
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {simSubscriptionEndDate
                                            ? simSubscriptionEndDate
                                            : "NA"}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          <Tooltip
                                            title={
                                              row.eoltCompletedOn
                                                ? "EOLT Completed"
                                                : "EOLT not completed"
                                            }
                                            placement="right-end"
                                          >
                                            {row.eoltCompletedOn ? (
                                              <TaskAltIcon color="success" />
                                            ) : (
                                              <TaskAltIcon
                                                style={{ color: "grey" }}
                                              />
                                            )}
                                          </Tooltip>
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                          style={
                                            row.eoltReportTicket && {
                                              cursor: "pointer",
                                              color: "#57B793",
                                            }
                                          }
                                          onClick={() =>
                                            row.eoltReportTicket &&
                                            handleEoltReport(row)
                                          }
                                        >
                                          {/* <ValidateButton
                                          title={simSubscriptionEndDate}
                                         
                                        /> */}
                                          {row.eoltReportTicket
                                            ? row.eoltReportTicket
                                            : "NA"}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          <CustomButton
                                            variant="contained"
                                            onClick={() =>
                                              handleEoltLatest(row)
                                            }
                                            disabled={
                                              !row.eoltReportTicket || !row.imei
                                            }
                                          >
                                            Telematics Check
                                          </CustomButton>
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          <CustomButton
                                            variant="contained"
                                            onClick={() =>
                                              handleCreateKyc(
                                                row.imei,
                                                row.vin,
                                                row.vehicleType,
                                                row.vehicleModel,
                                                row.fuelType,
                                                row.engineNumber,
                                                row.yearOfmg,
                                                row.vehicleMake,
                                                row.customerID,
                                                row.customerName,
                                                row.customerMobile1,
                                                row.retailInvoiceNumber,
                                                row.customerAddress,
                                                row.customerEmail,
                                                row.proofOfAddress,
                                                row.proofOfIdentity,
                                                row.dealerCode,
                                                row.dealerName,
                                                row.dealerAddress,
                                                row.dealerTown,
                                                row.dealerContactName,
                                                row.dealerContactNumber,
                                              )
                                            }
                                            disabled={
                                              row.status == "KYC_APPROVED" ||
                                              row.status == "KYC_RECEIVED"
                                            }
                                          >
                                            Manual KYC
                                          </CustomButton>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          <CustomButton
                                            variant="contained"
                                            onClick={() =>
                                              openChangeVinNo(row)
                                            }
                                          >
                                            Vin No. change
                                          </CustomButton>
                                        </TableCell>
                                        {updatePermission ||
                                          deletePermission ? (
                                          <TableCell
                                            align="center"
                                            className={classes.tableContent}
                                          >
                                            <Grid
                                              container
                                              alignContent="center"
                                              style={{ height: "5px" }}
                                            >
                                              <Grid item>
                                                <Button
                                                  onClick={() =>
                                                    openImeiEdit(row)
                                                  }
                                                >
                                                  <CreateIcon
                                                    style={{
                                                      fill: "#2b2924",
                                                    }}
                                                  />
                                                </Button>
                                              </Grid>
                                              {/* <>
                                              <Grid item>
                                                <Button
                                                  onClick={() =>
                                                    openChangeVinNo(row)
                                                  }
                                                >
                                                  <BorderColor
                                                    style={{
                                                      fill: "#2b2924",
                                                    }}
                                                  />
                                                </Button>
                                              </Grid>
                                              </> */}
                                            </Grid>
                                          </TableCell>
                                        ) : null
                                        }
                                      </TableRow >
                                    );
                                  })
                                }
                              </TableBody >
                            </Table >
                          </TableContainer >
                          <TablePagination
                            // style={{ display: "inline-flex" }}
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            count={totalCount ? totalCount : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper >
                      </>
                    ) : (
                      <NoData />
                    )}
                  </Grid >
                </Grid >
              </Grid >
            </Grid >
          </Grid >
        </Grid >
      }
    </React.Fragment >
  );
}

export default withSnackbar(VinImeMap);
