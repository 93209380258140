import React, { useState, useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import useStyles from "./styles";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "throttle-debounce";
import {
  CustomCircularProgress,
  CustomDialogBox,
  NoData,
} from "../../hoc/CustomComponents";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
import moment from "moment";
// import CertificateUploadDialog from "./CertificateUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  InputAdornment,
  TablePagination,
  Paper,
} from "@mui/material";
import L2TicketUpdate from "./L2TicketUpdate";
import axios from "axios";

import Telematics from "../CertificationManager/Telematics";
import ImagePreview from "../Vehicles/ImagePreview";
import { client2 } from "../tables/client";
import AssignToL1 from "./AssignToL1";
import FileSaver from "file-saver";
import ViewServiceLogs from "../ServiceUserLogs/ViewServiceLogs";
import CertificateUploadDialog from "../CertificationManager/CertificateUpload";

const colums = [
  {
    label: "Sr.No",
    minWidth: 100,
    align: "center",
  },
  {
    label: "Request ID",
    minWidth: 200,
    align: "center",
  },
  {
    label: "VIN",
    minWidth: 100,
    align: "center",
  },
  {
    label: "Common Layer Upload",
    minWidth: 180,
    align: "center",
  },
  {
    label: "CLA Issued On",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Fitment Upload",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Vaahan Issued On",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Common Layer Download",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Project Name",
    minWidth: 200,
    align: "center",
  },
  {
    label: "InProgress Date",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device Model",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device Serial Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Device IMEI Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Engine Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "ICCID Number",
    minWidth: 100,
    align: "center",
  },
  {
    label: "Customer Name",
    minWidth: 350,
    align: "center",
  },
  {
    label: "Customer Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Customer OTP number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Customer Address",
    minWidth: 500,
    align: "center",
  },
  // {
  //   label: "Vehicle Name",
  //   minWidth: 200,
  //   align: "center",
  // },
  {
    label: "Vehicle Model",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Registration State",
    minWidth: 200,
    align: "center",
  },
  {
    label: "RTO Number",
    minWidth: 150,
    align: "center",
  },
  {
    label: "Dealer Name",
    minWidth: 350,
    align: "center",
  },
  {
    label: "Dealer Contact Number",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Alternate Dealer Name",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Alternate Dealer Number",
    minWidth: 200,
    align: "center",
  },

  {
    label: "Payment Status",
    minWidth: 150,
    align: "center",
  },
  {
    label: "SIM Activation status ",
    minWidth: 200,
    align: "center",
  },
  {
    label: "MSISDN1",
    minWidth: 200,
    align: "center",
  },
  {
    label: "MSISDN2",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Vehicle Type",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Year Of Manufacture",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Request Status",
    minWidth: 150,
    align: "center",
  },
  {
    label: "OTP",
    minWidth: 100,
    align: "center",
  },
  {
    label: "OTP Sent On",
    minWidth: 170,
    align: "center",
  },
  {
    label: "Reason",
    minWidth: 800,
    align: "center",
  },
  {
    label: "Attachments",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Telematics",
    minWidth: 150,
    align: "center",
  },

  {
    label: "Assign To L1",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Category",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Sub-Category",
    minWidth: 200,
    align: "center",
  },
];

const GET_LATEST_DETAILS = gql`
  query ($Vin: String!) {
    getDeviceDetailsByVin(Vin: $Vin) {
      code
      message
      data {
        vin
        imei
        latitude
        longitude
        serverTS
        packetType
        firmwareVersion
      }
    }
  }
`;

const GET_VEHICLE_CERTIFICATE = gql`
  query ($oemId: Int!, $status: Int!, $loginId: Int!, $serviceStatus: String, $offset: Int, $limit: Int, $search: String) {
    getOemVehicleData(
      oemId: $oemId
      status: $status
      loginId: $loginId
      serviceStatus: $serviceStatus
      offset: $offset
      limit: $limit
      search: $search
    ) {
      totalCount
      id
      ticket_number
      vin
      imei
      chassis_no
      vehicleModel
      status
      simPlanType
      inprogress_date
      device_model
      device_serialno
      engine_no
      customer_name
      customer_no
      state
      rto_name
      rto_no
      dealer_name
      alt_dealername
      alt_dealerno
      select_project
      customer_altno
      vehicle_oldnew
      vahaan_file
      common_file
      dealer_contact_name
      dealer_contact_number
      year_of_manufacture
      iccid
      payment_by
      otp
      otp_datetime
      otp_id
      expiry_on
      login_id
      msisdn1
      msisdn2
      customerOtp
      otpSendTime
      reason
      state_name
      customer_address
      vehicle_name
      payment_status
      ticketStatus
      ticketCategory
      ticketCategoryName
      ticketSubCategory
      ticketSubCategoryName
      requestStatus
      uploadFilename
      requestReason
      common_file_date
      vahaan_file_date
    }
  }
`;

const CREATE_LOGS = gql`
  mutation (
    $oemId: Int!
    $loginId: Int
    $otp: String
    $categoryId: Int!
    $subCategoryId: Int!
    $vin: String!
    $requestId: String!
    $ticketRemarks: String
    $ticketStatus: String
    $requestStatus: String
    $uploadFileName: String
    $ownerId: Int
  ) {
    createRequestLogs(
      oemId: $oemId
      loginId: $loginId
      otp: $otp
      categoryId: $categoryId
      subCategoryId: $subCategoryId
      vin: $vin
      requestId: $requestId
      ticketRemarks: $ticketRemarks
      ticketStatus: $ticketStatus
      requestStatus: $requestStatus
      uploadFileName: $uploadFileName
      ownerId: $ownerId
    ) {
      code
      status
      message
    }
  }
`;

const ASSIGN_TO_L1 = gql`
  mutation (
    $oemId: Int!
    $loginId: Int
    $categoryId: Int!
    $subCategoryId: Int!
    $vin: String!
    $requestId: String!
    $remarks: String
    $ownerId: Int
  ) {
    assignRequestToL1(
      oemId: $oemId
      loginId: $loginId
      categoryId: $categoryId
      subCategoryId: $subCategoryId
      vin: $vin
      requestId: $requestId
      remarks: $remarks

      ownerId: $ownerId
    ) {
      code
      status
      message
    }
  }
`;
const UPLOAD_TICKET_FILES = gql`
  mutation ($fileExtension: String!) {
    ticketLogsFileUpload(fileExtension: $fileExtension) {
      code
      remarks
      message
      filename
      url
    }
  }
`;

const GET_UPLOAD_URL = gql`
  query ($fileExtension: String!) {
    getCertificateFileUploadUrl(fileExtension: $fileExtension) {
      code
      remarks
      message
      filename
      url
    }
  }
`;

const UPDATE_FILE_STATUS = gql`
  mutation ($id: Int!, $fileType: String!, $Certificate: String!) {
    updateCompletedStatus(
      id: $id
      fileType: $fileType
      Certificate: $Certificate
    ) {
      code
      remarks
      message
    }
  }
`;

const GET_ADDRESS = gql`
  query ($plusCode: String, $lat: String, $lng: String) {
    getAddressForPluscode(plusCode: $plusCode, lat: $lat, lng: $lng) {
      statusCode
      location
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  query ($bucketName: String, $filename: String!) {
    getDownloadURL(bucketName: $bucketName, filename: $filename) {
      code
      remarks
      message
      url
    }
  }
`;
const columns = [
  {
    name: "Sr.No",
    minWidth: 100,
  },

  {
    name: "VIN",
    minWidth: 100,
  },
  {
    name: "Ticket ID",
    minWidth: 100,
  },
  {
    name: "Category",
    minWidth: 100,
  },
  {
    name: "Type",
    minWidth: 100,
  },
  {
    name: "Ticket Status",
    minWidth: 100,
  },
  {
    name: "Request Status",
    minWidth: 100,
  },
  {
    name: "Remarks",
    minWidth: 100,
  },
  {
    name: "Attachments",
    minWidth: 100,
  },
  {
    name: "CLA Issued On",
    minWidth: 100,
  },
  {
    name: "Vaahan Issued On",
    minWidth: 100,
  },
  {
    name: "Created On",
    minWidth: 100,
  },
];

function ServiceUserL2(props) {
  const classes = useStyles();
  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [certificateData, setCertificateData] = useState([]);
  const [allData, setAlldata] = useState([]);
  const [vin, setVin] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [otp, setOtp] = useState(null);
  const [remark, setRemarks] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [openTicket, setOpenTicket] = useState(false);
  const [login_id, setLoginId] = useState(null);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [openConfirm, setopenConfirm] = useState(false);
  const [filename, setFileName] = useState(null);
  const [allStatus, setStatuses] = useState(["INPROGRESS", "PENDING"]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [requestStatus, setrequestStatus] = useState(null);
  const [certificateId, setCertificateId] = useState(null);
  const [uploadFile, setuploadFile] = useState(null);
  const [Vahaan, setVahaan] = useState(true);
  const [Common, setCommon] = useState(true);
  const [vahanFile, setVahanFile] = useState(null);
  const [commonFile, setCommonFile] = useState(null);

  const [deviceTS, setDeviceTS] = useState(null);
  const [serverTS, setServerTS] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [pluscode, setPlucCode] = useState(null);
  const [packetType, setPacketType] = useState(null);

  const [eoltVin, setEoltVin] = useState(null);
  const [eoltImei, setEoltImei] = useState(null);
  const [openTelematics, setOpenTelematics] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(null);
  const [previewUrl, setUrl] = useState(null);
  const [validFile, setValidFile] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [openLogs, setOpenLogs] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [
    getVehicleCertificate,
    {
      loading: certficateLoading,
      error: certficateError,
      data: vehicleCertificateData,
    },
  ] = useLazyQuery(GET_VEHICLE_CERTIFICATE, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      // setRowsPerPage(rowsPerPage);
      // setPage(0);
      // setNext(0);
      console.log(result.getOemVehicleData, "datadat");
      setAlldata(result.getOemVehicleData);
      // setTotalCount(result.getOemVehicleData.length);
      setTotalCount(result?.getOemVehicleData[0]?.totalCount);
      setCertificateData(result.getOemVehicleData);
    },
  });

  /**
   * @summary opens the Assign modal
   */
  const handleUpdateTicket = (row) => {
    setVin(row.vin);
    setRequestId(row.ticket_number);
    setLoginId(row.login_id);
    setOpenTicket(true);
    setrequestStatus(row.requestStatus);
    setCategory(row.ticketCategoryName);
    setSubCategory(row.ticketSubCategoryName);
    setCategoryId(row.ticketCategory);
    setSubCategoryId(row.ticketSubCategory);
    setTicketStatus(row.ticketStatus);
    setOtp(row.otp);
    setRemarks(row.remark);
    //assing values to category and sub category
  };

  /**
   * @summary closes the update ticket  modal
   */
  const handleCloseAssign = () => {
    setOpenTicket(false);
    setValidFile(true);
    setRequestId(null);
  };

  /**
   * @summary handles the otp
   */
  const handleOtp = (e) => {
    setOtp(e);
  };

  /**
   * @summary creates the logs
   */
  const handleTicketSubmit = async () => {
    let variables = {
      oemId: props.oem,
      loginId: login_id,
      otp: otp,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      vin: vin,
      requestId: requestId,
      ticketRemarks: remark,
      ticketStatus: ticketStatus,
      uploadFileName: filename,
    };
    console.log(variables, "variables");
    await createLogs({
      variables: {
        oemId: props.oem,
        loginId: login_id,
        otp: otp,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        vin: vin,
        requestId: requestId,
        ticketRemarks: remark,
        ticketStatus: ticketStatus,
        uploadFileName: filename,
        requestStatus: requestStatus,
        ownerId: props.token.id,
      },
    });
  };

  /**
   * @summary handles the remark
   */
  const handleRemarks = (e) => {
    setRemarks(e);
  };

  /**
   * @summary handles the status
   */
  const handleTicketStatus = (e, sel) => {
    if (sel) {
      setTicketStatus(sel);
    } else {
      setTicketStatus(null);
    }
  };

  /**
   * @summary This closes the snackbar
   */
  const handleSnackbarClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  const [createLogs] = useMutation(CREATE_LOGS, {
    // fetchPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: (res) => {
      if (res.createRequestLogs.code == 200) {
        props.setMessage("Successfully updated the ticket");
        props.setNetworkError(true);
        props.setSnackbar(true);

        handleSnackbarClose();
        handleCloseAssign();
        getVehicleCertificate({
          variables: {
            status: 2,
            oemId: props.oem,
            loginId: -1,
            serviceStatus: "ASSIGNED_TO_L2",
            limit: rowsPerPage, 
            offset: page,
            search: searchInput,
          },
        });
      } else {
        props.setMessage(res.createRequestLogs.message || "Failed to Update");
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  const [assignToL1, { loading: assignLoading }] = useMutation(ASSIGN_TO_L1, {
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: (res) => {
      if (res.assignRequestToL1.code == 200) {
        props.setMessage(res.assignRequestToL1.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose();
        handleCloseConfim();
        setPage(0);
        setNext(0);
        setSearchInput("");
        getVehicleCertificate({
          variables: {
            status: 2,
            oemId: props.oem,
            loginId: -1,
            serviceStatus: "ASSIGNED_TO_L2",
            limit: rowsPerPage, 
            offset: page,
            // search: searchInput,
          },
        });
      } else {
        props.setMessage("Failed to Assign");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  const handleAssignL1 = (row) => {
    setopenConfirm(true);
    setLoginId(row.login_id);
    setRequestId(row.ticket_number);
    setVin(row.vin);
    setCategoryId(row.ticketCategory);
    setSubCategoryId(row.ticketSubCategory);
    setCategory(row.ticketCategoryName);
    setSubCategory(row.ticketSubCategoryName);
  };

  const handleCloseConfim = () => {
    setopenConfirm(false);
    setRequestId(null);
  };

  const handleConfirmAssign = async () => {

    await assignToL1({
      variables: {
        oemId: props.oem,
        loginId: login_id,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        vin: vin,
        requestId: requestId,
        remarks: remark,

        ownerId: props.token.id,
      },
    });
  };

  const upload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (file) {
      console.log(file, file.size, "size");
      props.setMessage("Uploading...");
      props.setNetworkError(false);
      props.setSnackbar(true);
      let fileSize = file.size / (1024 * 1024);
      console.log(fileSize, "fileSize");
      if (fileSize >= 3) {
        props.setMessage("File Size more than 3mb is not allowed");
        setValidFile(false);
        props.setNetworkError(true);
        handleSnackbarClose();
      } else {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );

        await uploadTicketFiles({
          variables: {
            fileExtension: fileExtension,
          },
          onCompleted: async (res) => {
            console.log(res, "res in upload");
            if (res.ticketLogsFileUpload.code == 200) {
              await axios.put(res.ticketLogsFileUpload?.url, file, {
                headers: {
                  'x-ms-blob-type': 'BlockBlob',
                },
                onUploadProgress: (data) => {
                  props.setSnackbar(false);
                  setValidFile(true);
                  props.setNetworkError(true);
                  setFileName(res.ticketLogsFileUpload.filename);
                },
              });
            } else {
              props.setMessage(
                res.ticketLogsFileUpload.message || "Failed to Upload"
              );
              setValidFile(false);
              props.setNetworkError(true);
              handleSnackbarClose();
            }
          },
        });
      }
    } else {
      alert("No file selected");
    }
  };

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUploadCommon = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    // setIsBulkUploadOpen(false);
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        props.setMessage(`Uploading....`);
        props.setSnackbar(true);
        await uploadURLForCommon({
          variables: {
            fileExtension,
          },
        });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  /**
   * @summary api call to upload common certificate
   */
  const [uploadURLForCommon] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      //handleClose();
      handleSnackbarClose();
    },
    onCompleted: async (response) => {
      console.log(response, "responseCommon");

      if (response && response.getCertificateFileUploadUrl.url) {
        await axios.put(response.getCertificateFileUploadUrl.url, uploadFile, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            // let percentage = Math.round((100 * data.loaded) / data.total);
          },
        });
        setCommonFile(response.getCertificateFileUploadUrl.filename);
        setCommon(false);
        props.setSnackbar(false);
      } else if (response && response.getCertificateFileUploadUrl.code == 404) {
        props.setMessage(response.getCertificateFileUploadUrl.message);
        props.setSnackbar(true);
        handleSnackbarClose();
      } else {
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });

  /**
   * @summary handles api call of  uploading ticket files
   */
  const [uploadTicketFiles] = useMutation(UPLOAD_TICKET_FILES, {
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
  });

  const handleOpen = (id, scope) => {
    setCertificateId(id);
    setFileType(scope);
    setUploadOpen(true);
  };

  const handleUploadModelClose = () => {
    setUploadOpen(false);
    setCertificateId(null);
    setFileType(null);
    setFileUpload(null);
  };
  const viewLogs = (log) => {
    setRequestId(log.ticket_number);
    setOpenLogs(true);
  };

  /**
   * @summary handles the api vahaan certificates
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (file) {
      setuploadFile(file);
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        props.setMessage(`Uploading....`);
        props.setSnackbar(true);
        await uploadURL({
          variables: {
            fileExtension,
          },
        });
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  /**
   * @summary api call to upload vahaan certificates
   */

  const [uploadURL] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleSnackbarClose();
    },
    onCompleted: async (response) => {
      console.log(response, "responseVahaan");

      if (response && response.getCertificateFileUploadUrl.url) {
        await axios.put(response.getCertificateFileUploadUrl.url, uploadFile, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            let percentage = Math.round((100 * data.loaded) / data.total);
          },
        });
        setFileUpload(response.getCertificateFileUploadUrl.filename);
        props.setSnackbar(false);
        // setVahaan(false);
      } else if (response && response.getCertificateFileUploadUrl.code == 404) {
        setFileUpload(null);
        props.setMessage(response.getCertificateFileUploadUrl.message);
        props.setSnackbar(true);
        handleSnackbarClose();
      } else {
        setFileUpload(null);
        props.setMessage("Something went wrong,Please Try again!");
        props.setSnackbar(true);
        handleSnackbarClose();
      }
    },
  });
  /**
   * @summary handles the upload certifictaes
   */
  const uploadCertificate = () => {
    console.log(certificateId, fileType, fileUpload, "variables");
    updateStatus({
      variables: {
        id: certificateId,
        fileType: fileType,
        Certificate: fileUpload,
      },
    });
  };
  /**
   * @summary api call to set kyc Details
   */
  const [
    updateStatus,
    { error: createError, loading: createloading, data: createData },
  ] = useMutation(UPDATE_FILE_STATUS, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      handleUploadModelClose();
      handleSnackbarClose();
    },
    onCompleted: async (result) => {
      console.log(result, "result");
      if (result.updateCompletedStatus.code == 200) {
        props.setMessage(result.updateCompletedStatus.message);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleUploadModelClose();
      } else {
        if (result.updateCompletedStatus.code != 200) {
          props.setMessage(result.updateCompletedStatus.message);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleUploadModelClose();
        }
      }
      handleSnackbarClose();
      await getVehicleCertificate({
        variables: {
          status: 2,
          oemId: props.oem,
          loginId: -1,
          serviceStatus: "ASSIGNED_TO_L2",
          limit: rowsPerPage,
          offset: page, 
          search: searchInput
        },
      });
    },
  });

  const [getLatestpacket] = useLazyQuery(GET_LATEST_DETAILS, {
    fetchPolicy: "network-only",
  });

  /**
   * @summary api call to get latest call
   */
  const handleTelematics = async (row) => {
    setEoltVin(row.vin);
    setEoltImei(row.imei);
    await getLatestpacket({
      variables: {
        Vin: row.vin,
      },
      onCompleted: (res) => {
        if (
          res.getDeviceDetailsByVin.code == 200 &&
          res.getDeviceDetailsByVin.data
        ) {
          let detail = res.getDeviceDetailsByVin.data;

          const deviceTS =
            detail.deviceTS &&
            moment.unix(detail.deviceTS).format("DD-MM-YYYY HH:mm:ss");
          // setDeviceTS(deviceTS);

          const serverTS = moment
            .unix(detail.serverTS)
            .format("DD-MM-YYYY HH:mm:ss");
          setServerTS(serverTS);
          setDeviceTS(serverTS);
          setLat(detail.latitude);
          setLong(detail.longitude);
          setPacketType(detail.packetType);
          if (detail.latitude && detail.longitude) {
            getAddress({
              variables: {
                lat: detail.latitude,
                lng: detail.longitude,
              },
              onCompleted: (res) => {
                setPlucCode(res.getAddressForPluscode.location);
              },
            });
          }
        } else {
          setServerTS(null);
          setDeviceTS(null);
          setLat(null);
          setLong(null);
          setPlucCode(null);
          setPacketType(null);
        }
      },
    });

    setOpenTelematics(true);
  };

  /**
   * @summary api call to get latest call
   */
  const getTelematics = async (imei) => {
    let token = localStorage.getItem("id_token");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_OEM_EOLT_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        imeiList: [`t_${imei}`],
      },
    };

    let res = axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        return response.data;
      })
      .catch((error) => {
        // console.log(error);
        return error;
      });
    return res;
  };

  const [getAddress, { error: addressError }] = useLazyQuery(GET_ADDRESS);

  const [getPreviewUrl, { data: previeUrl }] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
  });

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const DownloadPreview = (filename) => {
    const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);
    getPreviewUrl({
      variables: {
        // bucketName: "vecv-documents",
        bucketName:  process.env.REACT_APP_AZURE_DOCUMENTS_CONTAINER,
        filename: filename,
      },
      onCompleted: async (result) => {
        console.log(result);
        //   setPreviewOpen(true);
        //   setUrl(result.getDownloadURL.url);
        const res = await axios({
          url: result.getDownloadURL.url,
          method: "GET",
          headers: { Accept: "application/vnd.ms-excel" },
          responseType: "blob", // important
        });

        FileSaver.saveAs(new Blob([res.data]), `Attachment.${fileExtension}`);
      },
    });
  };
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    setTimeout(debounceSearchRender(event.target.value), 2000);
  };

  const debounceSearchRender = debounce(1000, async (event) => {
    setPage(0);
    setNext(0);
    if (event) {
      // const filteredRows = allData.filter((row) => {
      //   const searchText = event.toLowerCase().trim();

      //   return (
      //     (row.vin && row.vin.toLowerCase().includes(searchText)) ||
      //     (row.ticket_number &&
      //       row.ticket_number.toLowerCase().includes(searchText))
      //   );
      // });
      // // console.log("filteredRows;;;;;",filteredRows.length);
      // setCertificateData(filteredRows);
      // setTotalCount(filteredRows.length);
      // //to manage the pagination on search ,setting pagination to intial values
      // // setRowsPerPage(20);
      // setPage(0);
      // setNext(0);
      await getVehicleCertificate({
        variables: {
          status: 2,
          oemId: props.oem,
          loginId: -1,
          serviceStatus: "ASSIGNED_TO_L2",
          limit: rowsPerPage, 
          offset: 0,
          search: event.trim(),
        },
      });
    } else {
      await getVehicleCertificate({
        variables: {
          status: 2,
          oemId: props.oem,
          loginId: -1,
          serviceStatus: "ASSIGNED_TO_L2",
          limit: rowsPerPage, 
          offset: 0
        },
      });
    }
  });
  const closeTelematics = () => {
    setOpenTelematics(false);
  };
  const handleChangePage = async (event, newPage) => {
    // setSearchInput("");
    // setCertificateData(allData);  
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await getVehicleCertificate({
      variables: {
        status: 2,
        oemId: props.oem,
        loginId: -1,
        serviceStatus: "ASSIGNED_TO_L2",
        limit: rowsPerPage, 
        offset: newPage,
        search: searchInput.trim(),
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    // setSearchInput("");
    // setCertificateData(allData);
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await getVehicleCertificate({
      variables: {
        status: 2,
        oemId: props.oem,
        loginId: -1,
        serviceStatus: "ASSIGNED_TO_L2",
        limit: event.target.value, 
        offset: 0 ,
        search: searchInput.trim(),
      },
    });
  };
  useEffect(() => {
    getVehicleCertificate({
      variables: {
        status: 2,
        oemId: props.oem,
        loginId: -1,
        serviceStatus: "ASSIGNED_TO_L2",
        limit: rowsPerPage, 
        offset: page
      },
    });
    props.setSnackbar(false);
  }, [props.oem]);
  const [
    onFileDownload,
    { loading: downloadLoading, error: downloadError, data: downloadData },
  ] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",

  });
  const onDownload = (filename) => {
    onFileDownload({
      variables: {
        filename: filename,
      },
      onCompleted: async (result) => {
        if (result.getDownloadURL && result.getDownloadURL.url) {
          const url = result.getDownloadURL.url;

          const res = await axios({
            url: url,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob",
          });

          FileSaver.saveAs(new Blob([res.data]), filename);
        }
      },
    });
  };
  const closeLogs = () => {
    setOpenLogs(false);
    setRequestId(null);
  };
  return (
    <>
      {/* <ImagePreview
        previewOpen={previewOpen}
        handlePreviewClose={handlePreviewClose}
        url={previewUrl}
      /> */}
      <ViewServiceLogs
        open={openLogs}
        columns={columns}
        requestId={requestId}
        closeLogs={closeLogs}
      />
      <L2TicketUpdate
        open={openTicket}
        vin={vin}
        requestId={requestId}
        handleCloseAssign={handleCloseAssign}
        otp={otp}
        remark={remark}
        allStatus={allStatus}
        ticketStatus={ticketStatus}
        handleTicketSubmit={handleTicketSubmit}
        handleOtp={handleOtp}
        handleRemarks={handleRemarks}
        handleTicketStatus={handleTicketStatus}
        category={category}
        subCategory={subCategory}
        upload={upload}
        snackbarOpen={props.open}
        validFile={validFile}
      />
      {/* <CustomDialogBox
        open={openConfirm}
        content="Are you sure you want to Assign to L1?"
        negativeResponseHandler={handleCloseConfim}
        negativeResponseButtonText="Cancel"
        positiveResponseHandle={handleConfirmAssign}
        positiveResponseButtonText="Confirm"
      /> */}
      <AssignToL1
        open={openConfirm}
        vin={vin}
        requestId={requestId}
        category={category}
        subCategory={subCategory}
        handleCloseConfim={handleCloseConfim}
        handleConfirmAssign={handleConfirmAssign}
        handleRemarks={handleRemarks}
        assignLoading={assignLoading}
      />
      <CertificateUploadDialog
        isModelOpen={uploadOpen}
        handleModelClose={handleUploadModelClose}
        certificateId={certificateId}
        onUpload={onUpload}
        onSubmit={uploadCertificate}
        snackbarOpen={props.open}
        fileType={fileType}
        fileUpload={fileUpload}
      />
      <Telematics
        open={openTelematics}
        vin={eoltVin}
        imei={eoltImei}
        deviceTS={deviceTS}
        serverTS={serverTS}
        lat={lat}
        long={long}
        location={pluscode}
        packetType={packetType}
        closeTelematics={closeTelematics}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h4">Tickets</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: '0px' }}
            >
              <Grid
                container
                style={{
                  backgroundColor: "#FFFFFF",
                  paddingTop: "0px",
                  // marginLeft: "-10px !important",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingBottom: "8px",
                    paddingTop: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <TextField
                    placeholder="Search Number"
                    variant="outlined"
                    style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                    size="small"
                    value={searchInput}
                    onChange={(e) => handleSearchChange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ paddingLeft: "3px" }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textfield}
                  />
                </Grid>

                <Grid item xs={12} style={{ maxHeight: '60vh' }}>
                  {certficateLoading ? (
                    <CustomCircularProgress />
                  ) : certificateData && certificateData.length > 0 ? (
                    <Paper>
                      <TableContainer className={classes.container} style={{ maxHeight: "calc(100vh - 300px)" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {colums.map((column) => (
                                <>
                                  <TableCell
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      font: "normal normal 600 14px/18px Nunito Sans",
                                      letterSpacing: "0px",
                                      color: "#212121",
                                      borderRightWidth: 1,
                                      borderColor: "#E8E8E8",
                                      borderTop: "1px solid #E8E8E8",
                                      padding: '8px'
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                </>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {certificateData ? (
                              certificateData
                                // .slice(
                                //   page * rowsPerPage,
                                //   page * rowsPerPage + rowsPerPage
                                // )
                                .map((row, index) => {
                                  let inprogressDate = row.inprogress_date
                                    ? moment
                                      .unix(row.inprogress_date)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;

                                  let otpDateTime = row.otp_datetime
                                    ? moment
                                      .unix(row.otp_datetime)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  let otpSentOn = row.otpSendTime
                                    ? moment
                                      .unix(row.otpSendTime)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  let vahaanUpDt = row.vahaan_file_date
                                    ? moment
                                        .unix(row.vahaan_file_date)
                                        .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
  
                                  let commonUpDt = row.common_file_date
                                    ? moment
                                        .unix(row.common_file_date)
                                        .format("DD-MM-YYYY HH:mm:ss")
                                    : null;
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {next + (index + 1)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                        onClick={() => handleUpdateTicket(row)}
                                        style={{
                                          // pointerEvents:
                                          //   row.simPlanType ==
                                          //   "DUAL_PROFILE_ACTIVATION_INPROGRESS"
                                          //     ? "none"
                                          //     : false,
                                          cursor: "pointer",
                                          color: "#5CB593",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {row.ticket_number
                                          ? row.ticket_number
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                        onClick={() => viewLogs(row)}
                                        sx={{
                                          cursor: "pointer",
                                          color: "#5CB593",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {row.vin ? row.vin : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          variant="contained"
                                          onClick={() =>
                                            handleOpen(row.id, "common")
                                          }
                                          disabled={
                                            row.simPlanType !=
                                            "COMMERCIALLY_ACTIVATED" ||
                                            row.common_file !== null ||
                                            !row.customerOtp
                                          }
                                        >
                                          <CloudUploadIcon />
                                        </CustomButton>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {commonUpDt ? commonUpDt : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          variant="contained"
                                          onClick={() =>
                                            handleOpen(row.id, "vahaan")
                                          }
                                          disabled={
                                            row.simPlanType !=
                                            "COMMERCIALLY_ACTIVATED" ||
                                            row.vahaan_file !== null ||
                                            !row.customerOtp
                                          }
                                        >
                                          <CloudUploadIcon />
                                        </CustomButton>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {vahaanUpDt ? vahaanUpDt : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.common_file ? (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            onClick={() =>
                                              onDownload(row.common_file)
                                            }
                                            variant="contained"
                                          >
                                            Download
                                          </CustomButton>
                                        ) : (
                                          <CustomButton
                                            style={{ fontSize: "8px" }}
                                            variant="contained"
                                          >
                                            N/A
                                          </CustomButton>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.select_project
                                          ? row.select_project
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {inprogressDate ? inprogressDate : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.device_model
                                          ? row.device_model
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.device_serialno
                                          ? row.device_serialno
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.imei ? row.imei : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.engine_no ? row.engine_no : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.iccid ? row.iccid : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_name
                                          ? row.customer_name
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_no
                                          ? row.customer_no
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_altno
                                          ? row.customer_altno
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customer_address
                                          ? row.customer_address
                                          : "NA"}
                                      </TableCell>
                                      {/* <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicle_name}
                                      </TableCell> */}
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicleModel}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.state_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.rto_no ? row.rto_no : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealer_name}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.dealer_contact_number
                                          ? row.dealer_contact_number
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.alt_dealername
                                          ? row.alt_dealername
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.alt_dealerno}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.payment_status
                                          ? row.payment_status
                                          : "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.simPlanType}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.msisdn1}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.msisdn2}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.vehicle_oldnew}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.year_of_manufacture}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.requestStatus
                                          ? row.requestStatus
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.customerOtp
                                          ? row.customerOtp
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {otpSentOn ? otpSentOn : "NA"}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.requestReason
                                          ? row.requestReason
                                          : "NA"}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        {row.uploadFilename ? (
                                          <CustomButton
                                            variant="contained"
                                            onClick={() =>
                                              DownloadPreview(
                                                row.uploadFilename
                                              )
                                            }
                                          >
                                            Download
                                          </CustomButton>
                                        ) : (
                                          "NA"
                                        )}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          // variant="contained"
                                          onClick={() => handleTelematics(row)}
                                        // disabled={!eoltTS}
                                        >
                                          Telematics Check
                                        </CustomButton>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className={classes.tableContent}
                                      >
                                        <CustomButton
                                          variant="contained"
                                          onClick={() => handleAssignL1(row)}
                                          disabled={
                                            row.common_file || row.vahaan_file
                                          }
                                        >
                                          Assign
                                        </CustomButton>
                                      </TableCell>
                                      <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {" "}
                                      {row.ticketCategoryName
                                        ? row.ticketCategoryName
                                        : "NA"}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      {" "}
                                      {row.ticketSubCategoryName
                                        ? row.ticketSubCategoryName
                                        : "NA"}
                                    </TableCell>
                                    </TableRow>
                                  );
                                })
                            ) : (
                              <>No Records found!</>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        component="div"
                        count={totalCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <NoData />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withSnackbar(ServiceUserL2);
